import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import UploadIcon from '@material-ui/icons/CloudUpload';
import Done from '@material-ui/icons/Done';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import TranslatableButton from '../components/TranslatableButton';
import TranslatableIconButton from '../components/TranslatableIconButton';
import { uploadFile, UPLOAD_FILE_SUCCESS } from '../actions/app';

const styles = {
  FileUploader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '100%',
    flexWrap: 'wrap'
  },
  hiddenInput: {
    display: 'none'
  },
  inputRoot: {
    width: '25em'
  },
  preview: {
    height: '3rem',
    width: '4rem',
    objectFit: 'contain',
  },
  errors: {

  }
};

class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      previewSrc: null,
    };
    this.hiddenFileInput = React.createRef();
  }

  triggerInput = () => {
    this.hiddenFileInput.current.click();
  }

  displayPreview = () => {
    const file = this.hiddenFileInput.current.files[0];
    if (!!file) {
      let reader = new FileReader();
      reader.onloadend = (e) => {
        this.setState({
          previewSrc: e.target.result
        });
      }
      reader.readAsDataURL(file);
    }
  }

  submit = () => {
    const { name, description } = this.state;
    const { files } = this.hiddenFileInput.current;
    if (!!files && !!files[0] && files[0].type.match('image.*')) {
      this.props.uploadFile(name, description, files[0]).then(action => {
        if (action.type === UPLOAD_FILE_SUCCESS) {
          const eventFile = Object.values(action.response.entities.event_files)[0]
          this.props.onSelect(eventFile.urls)
        }
      });
    }
  }

  onChangeTextInput = (name) => (event) => this.setState({ [name]: event.target.value })

  render() {
    const { uploadStatus, classes, className, t } = this.props;

    return (
      <div className={clsx(classes.FileUploader, className)}>
        {!!this.state.previewSrc && (
          <img src={this.state.previewSrc} alt="preview" className={classes.preview} />
        )}
        <TranslatableButton
          onClick={this.triggerInput}
          leftIcon={<UploadIcon />}
          tPath={'buttons.select_image'}
          buttonProps={{ className: classes.uploadButton }}
        />
        <input type="file" accept="image/*" className={classes.hiddenInput} ref={this.hiddenFileInput} onChange={this.displayPreview} />
        <TextField
          label={t('labels.imageName')}
          value={this.state.name}
          onChange={this.onChangeTextInput('name')}
          classes={{
            root: classes.inputRoot
          }}
        />
        <TextField
          label={t('labels.imageDescription')}
          value={this.state.description}
          onChange={this.onChangeTextInput('description')}
          classes={{
            root: classes.inputRoot
          }}
          multiline
          rows={2}
          rowsMax={3}
        />
        {uploadStatus.isFetching ? (
          <CircularProgress color="inherit" />
        ) : (
          <TranslatableIconButton
            tPath="buttons.upload_image"
            onClick={this.submit}
            Icon={Done}
          />
        )}
        {!!uploadStatus.error && (
          <Typography color="error">
            {uploadStatus.error}
          </Typography>
        )}
      </div>
    );
  }

}

const mapStateToProps = ({ app }) => ({
  uploadStatus: app.uploadFile
});

const mapDispatchToProps = {
  uploadFile
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('Forms'),
  injectSheet(styles)
);

export default enhance(FileUploader);
