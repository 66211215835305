import React, { Component } from 'react';
import injectSheet from 'react-jss';
import IconButton from '@material-ui/core/IconButton';
import { translate } from 'react-i18next';
import DraggableTab from '../containers/DraggableTab';

const styles = (theme) => ({
  Tabs: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.875rem',
    color: theme.palette.primary.contrastText,
    flexWrap: 'wrap',
  },
  tab: {
    margin: '0 0.25rem',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    background: theme.palette.primary.main,
    borderRadius: '0.5rem 0.5rem 0 0',
    display: 'flex',
    alignItems: 'center',
    '&.active': {
      background: theme.palette.primary.dark,
    },
    '&.draggable': {
      cursor: 'grab',
      '&:active': {
        cursor: 'grabbing'
      }
    },
  },
  tabName: {
    margin: '0 0.5rem',
  },
  tabButton: {
    width: 'auto',
    height: 'auto',
    padding: 0
  },
  icon: {
    width: '1rem',
    height: '1rem',
    padding: '0.25rem'
  }
});

class Tabs extends Component {

  render() {
    const { classes, items, t, translateTabs } = this.props;

    return (
      <div className={classes.Tabs}>
        {items.map((item, index) => {
          const tab = (
            <div key={item.key} onClick={item.onClick} className={`${classes.tab} ${item.active ? 'active' : ''} ${item.draggable ? 'draggable' : ''}`}>
              {item.name && (
                <span className={classes.tabName}>
                  {translateTabs ? t(`tabs.${item.name}`) : item.name}
                </span>
              )}
              {item.iconButton && (
                <IconButton onClick={item.iconButton.onClick} classes={{root: classes.tabButton}} aria-label={item.iconButton.label}>
                  <item.iconButton.iconClass classes={{root: classes.icon}} />
                </IconButton>
              )}
            </div>
          );

          return item.draggable ? (
            <DraggableTab
              key={item.key}
              tabIndex={index}
              moveAction={item.moveAction}
              index={index}
            >
              {tab}
            </DraggableTab>
          ) : tab;
        })}
      </div>
    );
  }
}

export default translate('Forms')(injectSheet(styles)(Tabs));
