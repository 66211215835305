import React from 'react';
import { translate } from 'react-i18next';
import i18n from '../helpers/i18n';
import injectSheet from 'react-jss';

const styles = {
  BlockPlaceholder: {
    border: '5px dashed rgba(0, 0, 0, 0.5)',
    overflow: 'hidden',
  },
  h3: {
    margin: 10,
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.5)',
    fontFamily: 'Quattrocento Sans, sans-serif'
  },
};

const BlockPlaceholder = ({ classes, t, isNavbarPlaceholder, isFooterPlaceholder }) => (
  <div className={classes.BlockPlaceholder}>
    <h3 className={classes.h3}>
      {isNavbarPlaceholder ?
        t('placeholder.blocks.navbar') : (isFooterPlaceholder ?
        t('placeholder.blocks.footer') :
        t('placeholder.blocks.content')
        )
      }
    </h3>
  </div>
);

export default translate('Forms', { i18n })(injectSheet(styles)(BlockPlaceholder));
