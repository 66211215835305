import React, { Component } from 'react';
import i18n from './helpers/i18n';
import i18nSite from './helpers/i18nSite';
import Composer from './pages/Composer';
import Preview from './pages/Preview';
import GlobalSettings from './pages/GlobalSettings';
import Errors from './pages/Errors';
import Login from './containers/Login';
import { loggedIn } from "./helpers/auth";
import { Switch, Route } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/ca';
import 'moment/locale/nl';
import 'moment/locale/ar';
import 'moment/locale/vi';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import 'moment/locale/ko';
import 'moment/locale/ja';

class App extends Component {
  componentWillMount() {
    i18n.changeLanguage(this.props.match.params.locale);
    moment.locale(i18nSite.language);
  }

  componentWillReceiveProps(nextProps) {
    const old_locale = this.props.match.params.locale;
    const new_locale = nextProps.match.params.locale;

    if (old_locale !== new_locale) {
      i18n.changeLanguage(new_locale);
      moment.locale(i18nSite.language);
    }
  }

  render() {
    const { match } = this.props;

    return (
      <div className="App">
        <Route path={`${match.path}/eventtia-sso/:sso_token`} component={Login}/>
        {loggedIn() ? (
          <Switch>
            <Route exact path={`${match.path}`} component={Composer}/>
            <Route exact path={`${match.path}/preview/:page?`} component={Preview}/>
            <Route exact path={`${match.path}/settings`} component={GlobalSettings}/>
          </Switch>
        ) : (
          <Route render={() => <Errors message="Please log in through Eventtia to use composer" />} />
        )}
      </div>
    );
  }
}

export default App;
