import React, { Component } from 'react';
import Errors from '../pages/Errors';
import { login } from "../actions/app";
import CircularProgress from '@material-ui/core/CircularProgress';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';

const styles = {
  Login: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  progress: {
    color: 'white'
  }
};

class Login extends Component {

  componentWillMount() {
    const { match, login } = this.props;
    const { sso_token } = match.params;

    login(sso_token);
  }

  componentWillReceiveProps(nextProps) {
    const { history, match } = this.props;
    const { loginData } = nextProps;
    const { locale } = match.params;

    if (loginData.logged) history.replace(`/${locale || 'en'}`);
  }

  render() {
    const { classes, loginData } = this.props;

    return (
      <div className={classes.Login}>
        {(loginData.isFetching) && (
          <CircularProgress className={classes.progress} size={50}/>
        )}

        {(loginData.error) && (
          <Errors message={loginData.error} />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ app, site }) => ({
  loginData: { ...app.login }
});

const mapDispatchToProps = (dispatch) => ({
  login: (sso_token) => {
    dispatch(login(sso_token));
  }
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectSheet(styles)
);

export default enhance(Login);
