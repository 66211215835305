import Forms from '../locales/Forms';
import Misc from 'composer-blocks/lib/locales/Misc.js';

const translations = {};
['en', 'es', 'fr', 'it', 'de', 'ca', 'nl', 'ar', 'vi', 'zh', 'zh-TW', 'ko', 'ja'].forEach((lang) => {
  translations[lang] = {
    Forms: { ...Forms[lang] },
    Misc: { ...Misc[lang] },
  };
});

export default translations;
