export const
  ENGLISH = 'en',
  SPANISH = 'es',
  FRENCH = 'fr',
  GERMAN = 'de',
  ITALIAN = 'it',
  CATALAN = 'ca',
  DUTCH = 'nl',
  ARABIC = 'ar',
  VIETNAMESE = 'vi',
  SIMPLIFIED_CHINESE = 'zh',
  TRADITIONAL_CHINESE = 'zh-TW',
  KOREAN = 'ko',
  JAPANESE = 'ja'
;

const languages = {
  [ENGLISH]: {locale: ENGLISH, name: 'English'},
  [SPANISH]: {locale: SPANISH, name: 'Español'},
  [FRENCH]: {locale: FRENCH, name: 'Français'},
  [GERMAN]: {locale: GERMAN, name: 'Deutsch'},
  [ITALIAN]: {locale: ITALIAN, name: 'Italiano'},
  [CATALAN]: {locale: CATALAN, name: 'Català'},
  [DUTCH]: {locale: DUTCH, name: 'Nederlands'},
  [ARABIC]: {locale: ARABIC, name: 'اَلْعَرَبِيَّةُ'},
  [VIETNAMESE]: {locale: VIETNAMESE, name: 'tiếng Việt'},
  [SIMPLIFIED_CHINESE]: {locale: SIMPLIFIED_CHINESE, name: '中文（简体）'},
  [TRADITIONAL_CHINESE]: {locale: TRADITIONAL_CHINESE, name: '中文（繁體）'},
  [KOREAN]: {locale: KOREAN, name: '한국어'},
  [JAPANESE]: {locale: JAPANESE, name: '日本語'},
};

export default languages;
