import React from 'react';
import injectSheet from 'react-jss';
import Delete from '@material-ui/icons/Delete';
import Move from '@material-ui/icons/OpenWith'; // This was used instead of DragHandle because the icon looked much more intuitive
import FileCopy from '@material-ui/icons/FileCopy';
import Paste from '@material-ui/icons/AssignmentReturned'; // There is no paste icon in the package
import Duplicate from '@material-ui/icons/Filter2'; // There is no duplicate icon in the package
import { translate } from 'react-i18next';
import i18n from '../helpers/i18n';

const styles = (theme) => ({
  EditionMenu: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Quattrocento Sans, sans-serif',
    display: 'none',
    position: 'absolute',
    padding: '0 1rem',
    top: '-2.9rem',
    right: 0,
    background: theme.palette.primary.main,
    boxShadow: '0 2px 3px 0 rgba(0,0,0,0.5)',
    borderRadius: '0.5rem 0.5rem 0 0.5rem',
    zIndex: 20,
    overflow: 'hidden',
    transition: 'opacity 0.2s',
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '0.6rem',
      alignItems: 'center',
      padding: '0.5rem',
      borderRadius: '0.25rem',
      cursor: 'pointer',
      '&.draggable': {
        cursor: 'grab',
        '&:active': {
          cursor: 'grabbing'
        }
      },
      '& > svg': {
        marginBottom: '0.25rem',
        height: '1rem',
        width: '1rem',
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.15)'
      }
    }
  },
  MenuAndHighlight: {
    '&:hover': {
      '& $EditionMenu': {
        display: 'flex',
        alignItems: 'center'
      }
    },
  },
  highlight: {
    display: 'block',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2)',
      boxShadow: 'inset 0 0 0 1px white, inset 0 0 10px 0 orange, 0 0 10px 0 orange',
    },
  },
  hidden: {
    display: 'none',
  },
  contentTypeName: {
    margin: '0 0.25rem',
    fontSize: '0.875rem',
    textTransform: 'capitalize'
  },
});

const MenuAndHighlight = injectSheet(styles)(({ classes, connectDragSource, deleteContentOrBlock, contentTypeName, copyToClipboard, canPaste, paste, duplicate, isEditing, hideMenuIfEditing, t }) => (
  <div className={classes.MenuAndHighlight}>
    <div className={classes.EditionMenu}>
      <label className={classes.contentTypeName}>
        {contentTypeName}
      </label>

      {(contentTypeName !== 'navbar' && contentTypeName !== 'footer') && connectDragSource(
        <span className="draggable">
            <Move />
          {t('buttons.move')}
        </span>
      )}
      {(contentTypeName !== 'navbar' && contentTypeName !== 'footer') && (
        <span onClick={copyToClipboard}>
            <FileCopy />
          {t('buttons.copy')}
        </span>
      )}
      {(contentTypeName !== 'navbar' && contentTypeName !== 'footer') && (canPaste) && (
        <span onClick={paste}>
            <Paste />
          {t('buttons.paste')}
        </span>
      )}
      <span onClick={duplicate}>
          <Duplicate />
        {t('buttons.duplicate')}
      </span>
      <span onClick={deleteContentOrBlock}>
          <Delete />
        {t('buttons.delete')}
      </span>
    </div>
    <div className={`${classes.highlight} ${isEditing && hideMenuIfEditing ? classes.hidden : ''}`} />
  </div>
));

export default translate('Forms', { i18n })(MenuAndHighlight);
