import * as blockTypes from 'composer-blocks/lib/constants/BlockTypes';

export default {
  en: {
    placeholder: {
      ageGateDescription: 'Ex: You must be 18 or older to enter.',
      ageGateErrorMsg: 'Ex: You are not old enough to enter this site.',
      blocks: {
        navbar: 'Drag & Drop the navbar blocks here',
        content: 'Drag & Drop the building blocks here',
        footer: 'Drag & Drop the footer blocks here',
      },
    },
    tabs: {
      stockImages: 'Stock Images',
      userImages: 'User Images',
      uploadImage: 'Upload Image',
    },
    buttons: {
      previewAs_desktop: 'Preview as desktop',
      previewAs_tablet: 'Preview as tablet',
      previewAs_mobile: 'Preview as mobile',
      globalSettings: 'Global Settings',
      preview: 'Preview',
      chooseUiLang: 'Choose UI Language',
      saveAllChanges: 'Save All Changes',
      publish: 'Publish',
      close: 'Close',
      back: 'Back',
      back_to_composer: 'Back to Composer',
      restore: 'Restore',
      add_content: 'Add Content',
      select_image: 'Select Image',
      manage_slides: 'Manage Slides',
      add_slide: 'Add Slide',
      manage_links: 'Manage Links',
      manage_iframe_src: "Manage Iframe link",
      manage_information: 'Manage Information',
      manage_colours: 'Manage colors',
      manage_attendee_types: 'Manage attendee types',
      manage_workshop_categories: 'Manage workshop categories',
      add_font: 'Add font',
      add_link: 'Add Link',
      add_color: 'Add Color',
      apply_changes: 'Apply Changes',
      discard_changes: 'Discard Changes',
      url_image: 'Image from URL',
      upload_image: 'Upload image',
      edit_code: 'Edit Code',
      delete_page: 'Delete Page',
      duplicate_page: 'Duplicate Page',
      copy_block: 'Copy block',
      paste_block: 'Paste block',
      duplicate_block: 'Duplicate block',
      copy_content: 'Copy content',
      paste_content: 'Paste content',
      change_friendly_url: 'Change friendly URL',
      move: 'Move',
      delete: 'Delete',
      copy: 'Copy',
      paste: 'Paste',
      duplicate: 'Duplicate',
      select_font: 'Select Font',
      change_font: 'Change font',
      update_global_font: 'Update site fonts',
      edit: 'Edit',
      update:"Update",
    },
    errors:{
      pageNameEmpty: "Page name can't be empty",
      duplicateNameOrUrl: 'Another page has the same name or URL ({{otherPage}})',
      invalidUrl: 'The URL contains invalid characters',
      not_image_format: 'Invalid image format',
      not_font_format: "Invalid font format",
      exceeded_limit: 'File size limit exceeded',
      empty_name: 'The name field is required',
      empty_password: 'Password cannot be empty',
    },
    info:{
      valid_font_formats: "Only ttf and woff formats are allowed.",
      first_font_is_global: 'The first font you choose will be used as default for this site',
      default_font_is: 'Default font: {{font}}',
    },
    labels: {
      pageName: 'Page name',
      friendlyUrl: 'Friendly URL',
      imageName: 'Name',
      imageDescription: 'Description',
      or: 'Or',
      customUrl: 'Custom URL / CSS gradient',
      attendee_fields: 'Attendee Fields',
      b2b_fields: 'Networking Fields',
      b2b_how_to: 'Choose the information to display for one participant type by dragging the available fields.',
      networking_profile: 'Networking profile',
      attendee_type: 'Attendee type',
      types_to_show:'Networking Profiles to be shown on the website',
      hide_navbar: 'Hide Navbar',
      hide_from_navbar: 'Hide from the Navbar',
      hide_footer: 'Hide Footer',
      hide_from_footer: 'Hide from the Footer',
      text: 'Text',
      open_in_new_tab: 'Open link in new tab',
      description: 'Description (optional)',
      name: 'Name (required)',
      scoped_css_note: 'Note: please use appropriately scoped rules in your custom CSS; using global rules might negatively affect the styles of rest of your site.',
      js_note: 'Note: please be mindful of what you insert here; any syntax or runtime errors might break your website.',
      placeholder_b2b_drop: 'Drop or write the fields you want to use inside ${}. For example ${Myfield}',
      fonts: 'Fonts',
      site_fonts: 'Fonts used in this site',
      fonts_in_account: 'Fonts in your account',
      font_preview: 'Preview',
      max_width: 'Site Max Width',
      allow_indexing: 'Allow search engine indexing',
      indexing:'Search engine indexing',
      site_password: 'Site password',
      enable_site_password: 'Enable site password',
      backdrop_color: 'Backdrop color',
      popup_color: 'Pop up color',
      button_color: 'Button color',
      error_msg: 'Error message',
      minimum_age: 'Minimum age',
      security: 'Security',
      enable_age_gate: 'Enable age restriction to site',
      password: 'Password',
      login_main_color: 'Login main color',
      iframe_note: "To guarantee a correct resizing of the iframe, the target website must have the iframe-resizer library script.",
    },
    warnings:{
      create_conference_and_participant: 'Please create a conference and at least one participant',
    },
    block_types: {
      [blockTypes.REGISTRATION]: 'Registration',
      [blockTypes.REGISTRATION_TYPES]: 'Registration types',
      [blockTypes.B2B_MATCHMAKING]: 'B2B matchmaking',
      [blockTypes.COOKIE_CONSENT]: 'Cookie consent',
      [blockTypes.NETWORKING_LOGIN]: 'Networking login',
      [blockTypes.ATTENDEES]: 'Attendees',
      [blockTypes.SPEAKERS]: 'Speakers',
      [blockTypes.SPONSORS]: 'Sponsors',
      [blockTypes.NAVBAR]: 'Navbar',
      [blockTypes.FOOTER]: 'Footer',
      [blockTypes.CUSTOM]: 'Custom',
      [blockTypes.WORKSHOPS]: 'Activities',
      [blockTypes.MAPS]: 'Maps',
      [blockTypes.HEADERS]: 'Headers',
      [blockTypes.EXHIBITORS]: 'Exhibitors',
      [blockTypes.FREE]: 'Free block',
      [blockTypes.CONTACT]: 'Contact Form',
    }
  },
  es: {
    placeholder: {
      ageGateDescription: "Ej: Debes tener 18 años o más para entrar.",
      ageGateErrorMsg:
        "Ej: No tienes la edad suficiente para entrar en este sitio.",
      blocks: {
        navbar: "Arrastra y suelta los bloques de la barra de navegación aquí",
        content: "Arrastra y suelta los bloques de contenido aquí",
        footer: "Arrastra y suelta los bloques de pie de página aquí",
      },
    },
    tabs: {
      stockImages: "Imágenes de Stock",
      userImages: "Imágenes de Usuario",
      uploadImage: "Subir Imagen",
    },
    buttons: {
      previewAs_desktop: "Vista previa en escritorio",
      previewAs_tablet: "Vista previa en tablet",
      previewAs_mobile: "Vista previa en móvil",
      globalSettings: "Configuraciones Globales",
      preview: "Vista previa",
      chooseUiLang: "Elige el idioma de la interfaz",
      saveAllChanges: "Guardar todos los cambios",
      publish: "Publicar",
      close: "Cerrar",
      back: "Atrás",
      back_to_composer: "Volver al Compositor",
      restore: "Restaurar",
      add_content: "Agregar Contenido",
      select_image: "Seleccionar Imagen",
      manage_slides: "Gestionar Diapositivas",
      add_slide: "Agregar Diapositiva",
      manage_links: "Gestionar Enlaces",
      manage_iframe_src: "Gestionar enlace de Iframe",
      manage_information: "Gestionar Información",
      manage_colours: "Gestionar colores",
      manage_attendee_types: "Gestionar tipos de asistentes",
      manage_workshop_categories: "Gestionar categorías de talleres",
      add_font: "Agregar fuente",
      add_link: "Agregar Enlace",
      add_color: "Agregar Color",
      apply_changes: "Aplicar Cambios",
      discard_changes: "Descartar Cambios",
      url_image: "Imagen desde URL",
      upload_image: "Subir imagen",
      edit_code: "Editar Código",
      delete_page: "Eliminar Página",
      duplicate_page: "Duplicar Página",
      copy_block: "Copiar bloque",
      paste_block: "Pegar bloque",
      duplicate_block: "Duplicar bloque",
      copy_content: "Copiar contenido",
      paste_content: "Pegar contenido",
      change_friendly_url: "Cambiar URL amigable",
      move: "Mover",
      delete: "Eliminar",
      copy: "Copiar",
      paste: "Pegar",
      duplicate: "Duplicar",
      select_font: "Seleccionar Fuente",
      change_font: "Cambiar fuente",
      update_global_font: "Actualizar fuentes del sitio",
      edit: "Editar",
      update: "Actualizar",
    },
    errors: {
      pageNameEmpty: "El nombre de la página no puede estar vacío",
      duplicateNameOrUrl:
        "Otra página tiene el mismo nombre o URL ({{otherPage}})",
      invalidUrl: "La URL contiene caracteres inválidos",
      not_image_format: "Formato de imagen no válido",
      not_font_format: "Formato de fuente no válido",
      exceeded_limit: "Se ha superado el límite de tamaño de archivo",
      empty_name: "El campo nombre es obligatorio",
      empty_password: "La contraseña no puede estar vacía",
    },
    info: {
      valid_font_formats: "Sólo se permiten formatos ttf y woff.",
      first_font_is_global:
        "La primera fuente que elijas se usará como predeterminada para este sitio",
      default_font_is: "Fuente predeterminada: {{font}}",
    },
    labels: {
      pageName: "Nombre de la página",
      friendlyUrl: "URL amigable",
      imageName: "Nombre",
      imageDescription: "Descripción",
      or: "O",
      customUrl: "URL Personalizado / Gradiente CSS",
      attendee_fields: "Campos de Asistentes",
      b2b_fields: "Campos de Networking",
      b2b_how_to:
        "Elige la información a mostrar para un tipo de participante arrastrando los campos disponibles.",
      networking_profile: "Perfil de Networking",
      attendee_type: "Tipo de Asistente",
      types_to_show: "Perfiles de Networking a mostrar en el sitio web",
      hide_navbar: "Ocultar Navbar",
      hide_from_navbar: "Ocultar de la Navbar",
      hide_footer: "Ocultar Footer",
      hide_from_footer: "Ocultar del Footer",
      text: "Texto",
      open_in_new_tab: "Abrir enlace en nueva pestaña",
      description: "Descripción (opcional)",
      name: "Nombre (obligatorio)",
      scoped_css_note:
        "Nota: por favor utiliza reglas correctamente escopadas en tu CSS personalizado; usar reglas globales puede afectar negativamente los estilos del resto de tu sitio.",
      js_note:
        "Nota: por favor ten cuidado con lo que insertas aquí; cualquier error de sintaxis o de tiempo de ejecución podría romper tu sitio web.",
      placeholder_b2b_drop:
        "Suelta o escribe los campos que deseas usar dentro de ${}. Por ejemplo ${Myfield}",
      fonts: "Fuentes",
      site_fonts: "Fuentes usadas en este sitio",
      fonts_in_account: "Fuentes en tu cuenta",
      font_preview: "Vista previa",
      max_width: "Ancho Máx del Sitio",
      allow_indexing: "Permitir indexación por motores de búsqueda",
      indexing: "Indexación por motores de búsqueda",
      site_password: "Contraseña del sitio",
      enable_site_password: "Habilitar contraseña del sitio",
      backdrop_color: "Color de Fondo",
      popup_color: "Color del Popup",
      button_color: "Color del Botón",
      error_msg: "Mensaje de error",
      minimum_age: "Edad mínima",
      security: "Seguridad",
      enable_age_gate: "Habilitar restricción de edad en el sitio",
      password: "Contraseña",
      login_main_color: "Color principal de inicio de sesión",
      iframe_note:
        "Para garantizar un redimensionamiento correcto del iframe, el sitio web objetivo debe tener el script de la biblioteca iframe-resizer.",
    },
    warnings: {
      create_conference_and_participant:
        "Por favor crea una conferencia y al menos un participante",
    },
    block_types: {
      [blockTypes.REGISTRATION]: "Registro",
      [blockTypes.REGISTRATION_TYPES]: "Tipos de Registro",
      [blockTypes.B2B_MATCHMAKING]: "B2B matchmaking",
      [blockTypes.COOKIE_CONSENT]: "Consentimiento de Cookies",
      [blockTypes.NETWORKING_LOGIN]: "Inicio de sesión de Networking",
      [blockTypes.ATTENDEES]: "Asistentes",
      [blockTypes.SPEAKERS]: "Ponentes",
      [blockTypes.SPONSORS]: "Patrocinadores",
      [blockTypes.NAVBAR]: "Navbar",
      [blockTypes.FOOTER]: "Footer",
      [blockTypes.CUSTOM]: "Personalizado",
      [blockTypes.WORKSHOPS]: "Actividades",
      [blockTypes.MAPS]: "Mapas",
      [blockTypes.HEADERS]: "Encabezados",
      [blockTypes.EXHIBITORS]: "Expositores",
      [blockTypes.FREE]: "Bloque gratuito",
      [blockTypes.CONTACT]: "Formulario de Contacto",
    },
  },
  fr: {
    placeholder: {
      ageGateDescription: "Ex : Vous devez avoir 18 ans ou plus pour entrer.",
      ageGateErrorMsg:
        "Ex : Vous n'êtes pas assez vieux pour entrer sur ce site.",
      blocks: {
        navbar: "Déplacez et déposez les blocs de la barre de navigation ici",
        content: "Déplacez et déposez les blocs de construction ici",
        footer: "Déplacez et déposez les blocs de pied de page ici",
      },
    },
    tabs: {
      stockImages: "Images de Stock",
      userImages: "Images Utilisateur",
      uploadImage: "Télécharger une Image",
    },
    buttons: {
      previewAs_desktop: "Aperçu sur bureau",
      previewAs_tablet: "Aperçu sur tablette",
      previewAs_mobile: "Aperçu sur mobile",
      globalSettings: "Paramètres globaux",
      preview: "Aperçu",
      chooseUiLang: "Choisissez la langue de l'interface",
      saveAllChanges: "Sauvegarder tous les changements",
      publish: "Publier",
      close: "Fermer",
      back: "Retour",
      back_to_composer: "Retour au Compositeur",
      restore: "Restaurer",
      add_content: "Ajouter du contenu",
      select_image: "Sélectionner une image",
      manage_slides: "Gérer les diapositives",
      add_slide: "Ajouter une diapositive",
      manage_links: "Gérer les liens",
      manage_iframe_src: "Gérer le lien Iframe",
      manage_information: "Gérer les informations",
      manage_colours: "Gérer les couleurs",
      manage_attendee_types: "Gérer les types de participants",
      manage_workshop_categories: "Gérer les catégories d'ateliers",
      add_font: "Ajouter une police",
      add_link: "Ajouter un lien",
      add_color: "Ajouter une couleur",
      apply_changes: "Appliquer les modifications",
      discard_changes: "Annuler les modifications",
      url_image: "Image depuis l'URL",
      upload_image: "Télécharger l'image",
      edit_code: "Modifier le code",
      delete_page: "Supprimer la page",
      duplicate_page: "Dupliquer la page",
      copy_block: "Copier le bloc",
      paste_block: "Coller le bloc",
      duplicate_block: "Dupliquer le bloc",
      copy_content: "Copier le contenu",
      paste_content: "Coller le contenu",
      change_friendly_url: "Changer l'URL amicale",
      move: "Déplacer",
      delete: "Supprimer",
      copy: "Copier",
      paste: "Coller",
      duplicate: "Dupliquer",
      select_font: "Sélectionner une police",
      change_font: "Changer la police",
      update_global_font: "Mettre à jour les polices du site",
      edit: "Modifier",
      update: "Mettre à jour",
    },
    errors: {
      pageNameEmpty: "Le nom de la page ne peut pas être vide",
      duplicateNameOrUrl: "Une autre page a le même nom ou URL ({{otherPage}})",
      invalidUrl: "L'URL contient des caractères invalides",
      not_image_format: "Format d'image invalide",
      not_font_format: "Format de police invalide",
      exceeded_limit: "Limite de taille de fichier dépassée",
      empty_name: "Le champ nom est obligatoire",
      empty_password: "Le mot de passe ne peut pas être vide",
    },
    info: {
      valid_font_formats: "Seuls les formats ttf et woff sont autorisés.",
      first_font_is_global:
        "La première police que vous choisissez sera utilisée par défaut pour ce site.",
      default_font_is: "Police par défaut : {{font}}",
    },
    labels: {
      pageName: "Nom de la page",
      friendlyUrl: "URL conviviale",
      imageName: "Nom",
      imageDescription: "Description",
      or: "Ou",
      customUrl: "URL Personnalisée / Dégradé CSS",
      attendee_fields: "Champs de participants",
      b2b_fields: "Champs de mise en réseau",
      b2b_how_to:
        "Choisissez les informations à afficher pour un type de participant en faisant glisser les champs disponibles.",
      networking_profile: "Profil de mise en réseau",
      attendee_type: "Type de participant",
      types_to_show: "Profils de mise en réseau à afficher sur le site",
      hide_navbar: "Masquer la barre de navigation",
      hide_from_navbar: "Masquer de la barre de navigation",
      hide_footer: "Masquer le pied de page",
      hide_from_footer: "Masquer du pied de page",
      text: "Texte",
      open_in_new_tab: "Ouvrir le lien dans un nouvel onglet",
      description: "Description (facultatif)",
      name: "Nom (obligatoire)",
      scoped_css_note:
        "Remarque : veuillez utiliser des règles correctement définies dans votre CSS personnalisé ; utiliser des règles globales pourrait affecter négativement le style du reste de votre site.",
      js_note:
        "Remarque : veuillez faire attention à ce que vous insérez ici ; les erreurs de syntaxe ou d'exécution pourraient casser votre site.",
      placeholder_b2b_drop:
        "Déposez ou écrivez les champs que vous souhaitez utiliser à l'intérieur de ${}. Par exemple ${Myfield}",
      fonts: "Polices",
      site_fonts: "Polices utilisées sur ce site",
      fonts_in_account: "Polices de votre compte",
      font_preview: "Aperçu",
      max_width: "Largeur max du site",
      allow_indexing: "Autoriser l'indexation par les moteurs de recherche",
      indexing: "Indexation par les moteurs de recherche",
      site_password: "Mot de passe du site",
      enable_site_password: "Activer le mot de passe du site",
      backdrop_color: "Couleur de l'arrière-plan",
      popup_color: "Couleur du pop-up",
      button_color: "Couleur du bouton",
      error_msg: "Message d'erreur",
      minimum_age: "Âge minimum",
      security: "Sécurité",
      enable_age_gate: "Activer la restriction d'âge sur le site",
      password: "Mot de passe",
      login_main_color: "Couleur principale de la connexion",
      iframe_note:
        "Pour garantir un redimensionnement correct de l'iframe, le site cible doit avoir le script de la bibliothèque iframe-resizer.",
    },
    warnings: {
      create_conference_and_participant:
        "Veuillez créer une conférence et au moins un participant.",
    },
    block_types: {
      [blockTypes.REGISTRATION]: "Inscription",
      [blockTypes.REGISTRATION_TYPES]: "Types d'inscription",
      [blockTypes.B2B_MATCHMAKING]: "Mise en relation B2B",
      [blockTypes.COOKIE_CONSENT]: "Consentement aux cookies",
      [blockTypes.NETWORKING_LOGIN]: "Connexion mise en réseau",
      [blockTypes.ATTENDEES]: "Participants",
      [blockTypes.SPEAKERS]: "Intervenants",
      [blockTypes.SPONSORS]: "Sponsors",
      [blockTypes.NAVBAR]: "Barre de navigation",
      [blockTypes.FOOTER]: "Pied de page",
      [blockTypes.CUSTOM]: "Personnalisé",
      [blockTypes.WORKSHOPS]: "Ateliers",
      [blockTypes.MAPS]: "Cartes",
      [blockTypes.HEADERS]: "En-têtes",
      [blockTypes.EXHIBITORS]: "Exposants",
      [blockTypes.FREE]: "Bloc gratuit",
      [blockTypes.CONTACT]: "Formulaire de contact",
    },
  },
  de: {
    placeholder: {
      ageGateDescription:
        "Z.B.: Sie müssen 18 Jahre oder älter sein, um einzutreten.",
      ageGateErrorMsg:
        "Z.B.: Sie sind nicht alt genug, um diese Seite zu betreten.",
      blocks: {
        navbar: "Ziehen Sie die Navbar-Blöcke hierher",
        content: "Ziehen Sie die Bausteine hierher",
        footer: "Ziehen Sie die Footer-Blöcke hierher",
      },
    },
    tabs: {
      stockImages: "Stockbilder",
      userImages: "Benutzerbilder",
      uploadImage: "Bild Hochladen",
    },
    buttons: {
      previewAs_desktop: "Vorschau als Desktop",
      previewAs_tablet: "Vorschau als Tablet",
      previewAs_mobile: "Vorschau als Mobilgerät",
      globalSettings: "Globale Einstellungen",
      preview: "Vorschau",
      chooseUiLang: "Wählen Sie die UI-Sprache",
      saveAllChanges: "Alle Änderungen speichern",
      publish: "Veröffentlichen",
      close: "Schließen",
      back: "Zurück",
      back_to_composer: "Zurück zum Composer",
      restore: "Wiederherstellen",
      add_content: "Inhalt hinzufügen",
      select_image: "Bild auswählen",
      manage_slides: "Folien verwalten",
      add_slide: "Folie hinzufügen",
      manage_links: "Links verwalten",
      manage_iframe_src: "Iframe-Link verwalten",
      manage_information: "Informationen verwalten",
      manage_colours: "Farben verwalten",
      manage_attendee_types: "Teilnehmertypen verwalten",
      manage_workshop_categories: "Workshop-Kategorien verwalten",
      add_font: "Schriftart hinzufügen",
      add_link: "Link hinzufügen",
      add_color: "Farbe hinzufügen",
      apply_changes: "Änderungen anwenden",
      discard_changes: "Änderungen verwerfen",
      url_image: "Bild von URL",
      upload_image: "Bild hochladen",
      edit_code: "Code bearbeiten",
      delete_page: "Seite löschen",
      duplicate_page: "Seite duplizieren",
      copy_block: "Block kopieren",
      paste_block: "Block einfügen",
      duplicate_block: "Block duplizieren",
      copy_content: "Inhalt kopieren",
      paste_content: "Inhalt einfügen",
      change_friendly_url: "Freundliche URL ändern",
      move: "Verschieben",
      delete: "Löschen",
      copy: "Kopieren",
      paste: "Einfügen",
      duplicate: "Duplizieren",
      select_font: "Schriftart auswählen",
      change_font: "Schriftart ändern",
      update_global_font: "Stellen Sie die Schriftarten der Website ein",
      edit: "Bearbeiten",
      update: "Aktualisieren",
    },
    errors: {
      pageNameEmpty: "Der Seitenname darf nicht leer sein",
      duplicateNameOrUrl:
        "Eine andere Seite hat denselben Namen oder die gleiche URL ({{otherPage}})",
      invalidUrl: "Die URL enthält ungültige Zeichen",
      not_image_format: "Ungültiges Bildformat",
      not_font_format: "Ungültiges Schriftartformat",
      exceeded_limit: "Dateigröße überschreitet das Limit",
      empty_name: "Das Namensfeld ist erforderlich",
      empty_password: "Das Passwort darf nicht leer sein",
    },
    info: {
      valid_font_formats: "Nur ttf- und woff-Formate sind erlaubt.",
      first_font_is_global:
        "Die erste gewählte Schriftart wird als Standard für diese Seite verwendet.",
      default_font_is: "Standardschriftart: {{font}}",
    },
    labels: {
      pageName: "Seitenname",
      friendlyUrl: "Freundliche URL",
      imageName: "Name",
      imageDescription: "Beschreibung",
      or: "Oder",
      customUrl: "Benutzerdefinierte URL / CSS-Gradient",
      attendee_fields: "Teilnehmerfelder",
      b2b_fields: "Networking-Felder",
      b2b_how_to:
        "Wählen Sie die Informationen aus, die für einen Teilnehmertyp angezeigt werden sollen, indem Sie die verfügbaren Felder ziehen.",
      networking_profile: "Networking-Profil",
      attendee_type: "Teilnehmentyp",
      types_to_show: "Anzuzeigende Networking-Profile auf der Website",
      hide_navbar: "Navbar ausblenden",
      hide_from_navbar: "Von der Navbar ausblenden",
      hide_footer: "Fußzeile ausblenden",
      hide_from_footer: "Von der Fußzeile ausblenden",
      text: "Text",
      open_in_new_tab: "Link in neuem Tab öffnen",
      description: "Beschreibung (optional)",
      name: "Name (erforderlich)",
      scoped_css_note:
        "Hinweis: Bitte verwenden Sie angemessen angepasste Regeln in Ihrem benutzerdefinierten CSS; die Verwendung von globalen Regeln kann die Stile des Restes Ihrer Website negativ beeinflussen.",
      js_note:
        "Hinweis: Bitte achten Sie darauf, was Sie hier einfügen; Syntax- oder Laufzeitfehler könnten Ihre Website beschädigen.",
      placeholder_b2b_drop:
        "Ziehen Sie die Felder, die Sie in ${} verwenden möchten, hierher oder schreiben Sie sie. Zum Beispiel ${Myfield}",
      fonts: "Schriftarten",
      site_fonts: "In dieser Website verwendete Schriftarten",
      fonts_in_account: "Schriftarten in Ihrem Konto",
      font_preview: "Vorschau",
      max_width: "Maximale Breite der Website",
      allow_indexing: "Erlauben Sie die Indizierung durch Suchmaschinen",
      indexing: "Suchmaschinenindizierung",
      site_password: "Website-Passwort",
      enable_site_password: "Website-Passwort aktivieren",
      backdrop_color: "Hintergrundfarbe",
      popup_color: "Pop-up-Farbe",
      button_color: "Farbe des Buttons",
      error_msg: "Fehlermeldung",
      minimum_age: "Mindestalter",
      security: "Sicherheit",
      enable_age_gate: "Altersbeschränkung auf der Website aktivieren",
      password: "Passwort",
      login_main_color: "Hauptfarbe beim Login",
      iframe_note:
        "Um eine korrekte Größenanpassung des iFrames zu gewährleisten, muss die Zielwebsite das iframe-resizer-Skripte haben.",
    },
    warnings: {
      create_conference_and_participant:
        "Bitte erstellen Sie eine Konferenz und mindestens einen Teilnehmer.",
    },
    block_types: {
      [blockTypes.REGISTRATION]: "Registrierung",
      [blockTypes.REGISTRATION_TYPES]: "Registrierungsarten",
      [blockTypes.B2B_MATCHMAKING]: "B2B- matchmaking",
      [blockTypes.COOKIE_CONSENT]: "Cookie-Zustimmung",
      [blockTypes.NETWORKING_LOGIN]: "Networking-Login",
      [blockTypes.ATTENDEES]: "Teilnehmer",
      [blockTypes.SPEAKERS]: "Referenten",
      [blockTypes.SPONSORS]: "Sponsoren",
      [blockTypes.NAVBAR]: "Navbar",
      [blockTypes.FOOTER]: "Fußzeile",
      [blockTypes.CUSTOM]: "Benutzerdefiniert",
      [blockTypes.WORKSHOPS]: "Aktivitäten",
      [blockTypes.MAPS]: "Karten",
      [blockTypes.HEADERS]: "Köpfe",
      [blockTypes.EXHIBITORS]: "Aussteller",
      [blockTypes.FREE]: "Freier Block",
      [blockTypes.CONTACT]: "Kontaktformular",
    },
  },
  it: {
    placeholder: {
      ageGateDescription: "Es: Devi avere 18 anni o più per entrare.",
      ageGateErrorMsg:
        "Es: Non hai l'età sufficiente per entrare in questo sito.",
      blocks: {
        navbar: "Trascina e rilascia i blocchi della navbar qui",
        content: "Trascina e rilascia i mattoni qui",
        footer: "Trascina e rilascia i blocchi del piè di pagina qui",
      },
    },
    tabs: {
      stockImages: "Immagini Stock",
      userImages: "Immagini Utente",
      uploadImage: "Carica Immagine",
    },
    buttons: {
      previewAs_desktop: "Anteprima desktop",
      previewAs_tablet: "Anteprima tablet",
      previewAs_mobile: "Anteprima mobile",
      globalSettings: "Impostazioni Globali",
      preview: "Anteprima",
      chooseUiLang: "Scegli la lingua UI",
      saveAllChanges: "Salva tutte le modifiche",
      publish: "Pubblica",
      close: "Chiudi",
      back: "Indietro",
      back_to_composer: "Torna al Compositore",
      restore: "Ripristina",
      add_content: "Aggiungi contenuto",
      select_image: "Seleziona immagine",
      manage_slides: "Gestisci diapositive",
      add_slide: "Aggiungi diapositiva",
      manage_links: "Gestisci link",
      manage_iframe_src: "Gestisci link Iframe",
      manage_information: "Gestisci informazioni",
      manage_colours: "Gestisci colori",
      manage_attendee_types: "Gestisci tipi di partecipanti",
      manage_workshop_categories: "Gestisci categorie di workshop",
      add_font: "Aggiungi font",
      add_link: "Aggiungi link",
      add_color: "Aggiungi colore",
      apply_changes: "Applica modifiche",
      discard_changes: "Annulla modifiche",
      url_image: "Immagine da URL",
      upload_image: "Carica immagine",
      edit_code: "Modifica codice",
      delete_page: "Elimina pagina",
      duplicate_page: "Duplica pagina",
      copy_block: "Copia blocco",
      paste_block: "Incolla blocco",
      duplicate_block: "Duplica blocco",
      copy_content: "Copia contenuto",
      paste_content: "Incolla contenuto",
      change_friendly_url: "Cambia URL amichevole",
      move: "Muovi",
      delete: "Elimina",
      copy: "Copia",
      paste: "Incolla",
      duplicate: "Duplica",
      select_font: "Seleziona font",
      change_font: "Cambia font",
      update_global_font: "Aggiorna font del sito",
      edit: "Modifica",
      update: "Aggiorna",
    },
    errors: {
      pageNameEmpty: "Il nome della pagina non può essere vuoto",
      duplicateNameOrUrl:
        "Un'altra pagina ha lo stesso nome o URL ({{otherPage}})",
      invalidUrl: "L'URL contiene caratteri non validi",
      not_image_format: "Formato immagine non valido",
      not_font_format: "Formato font non valido",
      exceeded_limit: "Limite di dimensione del file superato",
      empty_name: "Il campo nome è obbligatorio",
      empty_password: "La password non può essere vuota",
    },
    info: {
      valid_font_formats: "Sono consentiti solo i formati ttf e woff.",
      first_font_is_global:
        "Il primo font che scegli sarà utilizzato come predefinito per questo sito",
      default_font_is: "Font predefinito: {{font}}",
    },
    labels: {
      pageName: "Nome della pagina",
      friendlyUrl: "URL amichevole",
      imageName: "Nome",
      imageDescription: "Descrizione",
      or: "Oppure",
      customUrl: "URL Personalizzata / Gradiente CSS",
      attendee_fields: "Campi partecipante",
      b2b_fields: "Campi Networking",
      b2b_how_to:
        "Scegli le informazioni da visualizzare per un tipo di partecipante trascinando i campi disponibili.",
      networking_profile: "Profilo di networking",
      attendee_type: "Tipo di partecipante",
      types_to_show: "Profili di networking da mostrare sul sito",
      hide_navbar: "Nascondi Navbar",
      hide_from_navbar: "Nascondi dalla Navbar",
      hide_footer: "Nascondi Footer",
      hide_from_footer: "Nascondi dal Footer",
      text: "Testo",
      open_in_new_tab: "Apri link in una nuova scheda",
      description: "Descrizione (opzionale)",
      name: "Nome (obbligatorio)",
      scoped_css_note:
        "Nota: usa regole appropriate nel tuo CSS personalizzato; utilizzare regole globali potrebbe influenzare negativamente gli stili del resto del tuo sito.",
      js_note:
        "Nota: fai attenzione a ciò che inserisci qui; eventuali errori di sintassi o di esecuzione potrebbero interrompere il funzionamento del tuo sito.",
      placeholder_b2b_drop:
        "Trascina o scrivi i campi che vuoi usare all'interno di ${}. Ad esempio ${Myfield}",
      fonts: "Font",
      site_fonts: "Font usati in questo sito",
      fonts_in_account: "Font nel tuo account",
      font_preview: "Anteprima",
      max_width: "Larghezza massima del sito",
      allow_indexing:
        "Consenti indicizzazione da parte dei motori di ricerca",
      indexing: "Indicizzazione dei motori di ricerca",
      site_password: "Password del sito",
      enable_site_password: "Abilita password del sito",
      backdrop_color: "Colore di sfondo",
      popup_color: "Colore del popup",
      button_color: "Colore del pulsante",
      error_msg: "Messaggio di errore",
      minimum_age: "Età minima",
      security: "Sicurezza",
      enable_age_gate: "Abilita restrizione di età sul sito",
      password: "Password",
      login_main_color: "Colore principale di accesso",
      iframe_note:
        "Per garantire un ridimensionamento corretto dell'iframe, il sito web di destinazione deve avere lo script della libreria iframe-resizer.",
    },
    warnings: {
      create_conference_and_participant:
        "Si prega di creare una conferenza e almeno un partecipante",
    },
    block_types: {
      [blockTypes.REGISTRATION]: "Registrazione",
      [blockTypes.REGISTRATION_TYPES]: "Tipi di registrazione",
      [blockTypes.B2B_MATCHMAKING]: "B2B matchmaking",
      [blockTypes.COOKIE_CONSENT]: "Consenso ai cookie",
      [blockTypes.NETWORKING_LOGIN]: "Accesso al networking",
      [blockTypes.ATTENDEES]: "Partecipanti",
      [blockTypes.SPEAKERS]: "Relatori",
      [blockTypes.SPONSORS]: "Sponsor",
      [blockTypes.NAVBAR]: "Navbar",
      [blockTypes.FOOTER]: "Footer",
      [blockTypes.CUSTOM]: "Personalizzato",
      [blockTypes.WORKSHOPS]: "Attività",
      [blockTypes.MAPS]: "Mappe",
      [blockTypes.HEADERS]: "Intestazioni",
      [blockTypes.EXHIBITORS]: "Espositori",
      [blockTypes.FREE]: "Blocco gratuito",
      [blockTypes.CONTACT]: "Modulo di contatto",
    },
  },
  ca: {
    placeholder: {
      ageGateDescription: "Ex: Heu de ser de 18 anys o més per entrar.",
      ageGateErrorMsg: "Ex: No sou prou grans per entrar a aquest lloc.",
      blocks: {
        navbar: "Arrossega i deixa caure els blocs de la barra de navegació aquí",
        content: "Arrossega i deixa caure els blocs de construcció aquí",
        footer: "Arrossega i deixa caure els blocs de peu de pàgina aquí",
      },
    },
    tabs: {
      stockImages: "Imatges de Stock",
      userImages: "Imatges d'Usuari",
      uploadImage: "Pujar Imatge",
    },
    buttons: {
      previewAs_desktop: "Previsualització en escriptori",
      previewAs_tablet: "Previsualització en tauleta",
      previewAs_mobile: "Previsualització en mòbil",
      globalSettings: "Configuracions Globals",
      preview: "Previsualització",
      chooseUiLang: "Tria l'idioma de la interfície",
      saveAllChanges: "Desa tots els canvis",
      publish: "Publicar",
      close: "Tancar",
      back: "Enrere",
      back_to_composer: "Tornar al compositor",
      restore: "Restaurar",
      add_content: "Afegir contingut",
      select_image: "Seleccionar imatge",
      manage_slides: "Gestionar diapositives",
      add_slide: "Afegir diapositiva",
      manage_links: "Gestionar enllaços",
      manage_iframe_src: "Gestionar l'enllaç de l'iframe",
      manage_information: "Gestionar informació",
      manage_colours: "Gestionar colors",
      manage_attendee_types: "Gestionar tipus d'assistents",
      manage_workshop_categories: "Gestionar categories de tallers",
      add_font: "Afegir tipografia",
      add_link: "Afegir enllaç",
      add_color: "Afegir color",
      apply_changes: "Aplicar canvis",
      discard_changes: "Descartar canvis",
      url_image: "Imatge des de l'URL",
      upload_image: "Pujar imatge",
      edit_code: "Editar codi",
      delete_page: "Eliminar pàgina",
      duplicate_page: "Duplicar pàgina",
      copy_block: "Copiar bloc",
      paste_block: "Enganxar bloc",
      duplicate_block: "Duplicar bloc",
      copy_content: "Copiar contingut",
      paste_content: "Enganxar contingut",
      change_friendly_url: "Canviar URL amigable",
      move: "Moure",
      delete: "Eliminar",
      copy: "Copiar",
      paste: "Enganxar",
      duplicate: "Duplicar",
      select_font: "Seleccionar tipografia",
      change_font: "Canviar tipografia",
      update_global_font: "Actualitzar les tipografies del lloc",
      edit: "Editar",
      update: "Actualitzar",
    },
    errors: {
      pageNameEmpty: "El nom de la pàgina no pot estar buit",
      duplicateNameOrUrl:
        "Una altra pàgina té el mateix nom o URL ({{otherPage}})",
      invalidUrl: "L'URL conté caràcters no vàlids",
      not_image_format: "Format d'imatge invàlid",
      not_font_format: "Format de tipografia invàlid",
      exceeded_limit: "Límit de mida de fitxer excedit",
      empty_name: "El camp de nom és obligatori",
      empty_password: "La contrasenya no pot estar buida",
    },
    info: {
      valid_font_formats: "Només s'accepten formats ttf i woff.",
      first_font_is_global:
        "La primera tipografia que escolliu s'utilitzarà com a predeterminada per a aquest lloc",
      default_font_is: "Tipografia predeterminada: {{font}}",
    },
    labels: {
      pageName: "Nom de la pàgina",
      friendlyUrl: "URL amigable",
      imageName: "Nom",
      imageDescription: "Descripció",
      or: "O",
      customUrl: "URL Personalitzada / Gradient CSS",
      attendee_fields: "Camps d'assistents",
      b2b_fields: "Camps de Networking",
      b2b_how_to:
        "Trieu la informació a mostrar per a un tipus de participant arrossegant els camps disponibles.",
      networking_profile: "Perfil de Networking",
      attendee_type: "Tipus d'assistent",
      types_to_show: "Perfils de Networking que es mostraran al lloc web",
      hide_navbar: "Amagar Navbar",
      hide_from_navbar: "Amagar del Navbar",
      hide_footer: "Amagar peu de pàgina",
      hide_from_footer: "Amagar del peu de pàgina",
      text: "Text",
      open_in_new_tab: "Obrir enllaç en una nova pestanya",
      description: "Descripció (opcional)",
      name: "Nom (obligatori)",
      scoped_css_note:
        "Nota: si us plau, utilitzeu regles adequadament específiques al vostre CSS personalitzat; utilitzar regles globals pot afectar negativament els estils de la resta del vostre lloc.",
      js_note:
        "Nota: si us plau, tingueu en compte el que hi feu; qualsevol error de sintaxi o d'execució pot trencar el vostre lloc web.",
      placeholder_b2b_drop:
        "Deixeu caure o escriviu els camps que voleu utilitzar dins de ${}. Per exemple ${Myfield}",
      fonts: "Tipografies",
      site_fonts: "Tipografies utilitzades en aquest lloc",
      fonts_in_account: "Tipografies al vostre compte",
      font_preview: "Previsualització",
      max_width: "Amplada màxima del lloc",
      allow_indexing: "Permetre la indexació del motor de cerca",
      indexing: "Indexació del motor de cerca",
      site_password: "Contrasenya del lloc",
      enable_site_password: "Habilitar la contrasenya del lloc",
      backdrop_color: "Color de fons",
      popup_color: "Color de pop-up",
      button_color: "Color del botó",
      error_msg: "Missatge d'error",
      minimum_age: "Edat mínima",
      security: "Seguretat",
      enable_age_gate: "Habilitar restricció d'edat per al lloc",
      password: "Contrasenya",
      login_main_color: "Color principal d'inici de sessió",
      iframe_note:
        "Per garantir un redimensionament correcte de l'iframe, el lloc web de destinació ha de tenir el script de la biblioteca iframe-resizer.",
    },
    warnings: {
      create_conference_and_participant:
        "Si us plau, creeu una conferència i almenys un participant",
    },
    block_types: {
      [blockTypes.REGISTRATION]: "Inscripció",
      [blockTypes.REGISTRATION_TYPES]: "Tipus d'inscripció",
      [blockTypes.B2B_MATCHMAKING]: "B2B matchmaking",
      [blockTypes.COOKIE_CONSENT]: "Consentiment de galetes",
      [blockTypes.NETWORKING_LOGIN]: "Inici de sessió de Networking",
      [blockTypes.ATTENDEES]: "Assistents",
      [blockTypes.SPEAKERS]: "Ponents",
      [blockTypes.SPONSORS]: "Sponsors",
      [blockTypes.NAVBAR]: "Navbar",
      [blockTypes.FOOTER]: "Peu de pàgina",
      [blockTypes.CUSTOM]: "Personalitzat",
      [blockTypes.WORKSHOPS]: "Activitats",
      [blockTypes.MAPS]: "Mapes",
      [blockTypes.HEADERS]: "Encapçalaments",
      [blockTypes.EXHIBITORS]: "Expositors",
      [blockTypes.FREE]: "Bloc gratuït",
      [blockTypes.CONTACT]: "Formulari de contacte",
    },
  },
  nl: {
    placeholder: {
      ageGateDescription:
        "Bijv: Je moet 18 jaar of ouder zijn om binnen te komen.",
      ageGateErrorMsg:
        "Bijv: Je bent niet oud genoeg om deze site te betreden.",
      blocks: {
        navbar: "Sleep de navbar-blokken hierheen",
        content: "Sleep de bouwstenen hierheen",
        footer: "Sleep de footer-blokken hierheen",
      },
    },
    tabs: {
      stockImages: "Stockafbeeldingen",
      userImages: "Gebruikerafbeeldingen",
      uploadImage: "Afbeelding Uploaden",
    },
    buttons: {
      previewAs_desktop: "Voorbeeld als desktop",
      previewAs_tablet: "Voorbeeld als tablet",
      previewAs_mobile: "Voorbeeld als mobiel",
      globalSettings: "Globale Instellingen",
      preview: "Voorbeeld",
      chooseUiLang: "Kies UI-taal",
      saveAllChanges: "Alle wijzigingen opslaan",
      publish: "Publiceren",
      close: "Sluiten",
      back: "Terug",
      back_to_composer: "Terug naar Composer",
      restore: "Herstellen",
      add_content: "Inhoud toevoegen",
      select_image: "Afbeelding selecteren",
      manage_slides: "Dia's beheren",
      add_slide: "Dia toevoegen",
      manage_links: "Links beheren",
      manage_iframe_src: "Iframe-link beheren",
      manage_information: "Informatie beheren",
      manage_colours: "Kleuren beheren",
      manage_attendee_types: "Deelnemer types beheren",
      manage_workshop_categories: "Workshop categorieën beheren",
      add_font: "Lettertype toevoegen",
      add_link: "Link toevoegen",
      add_color: "Kleur toevoegen",
      apply_changes: "Wijzigingen toepassen",
      discard_changes: "Wijzigingen afwijzen",
      url_image: "Afbeelding van URL",
      upload_image: "Afbeelding uploaden",
      edit_code: "Code bewerken",
      delete_page: "Pagina verwijderen",
      duplicate_page: "Pagina dupliceren",
      copy_block: "Blok kopiëren",
      paste_block: "Blok plakken",
      duplicate_block: "Blok dupliceren",
      copy_content: "Inhoud kopiëren",
      paste_content: "Inhoud plakken",
      change_friendly_url: "Vriendelijke URL wijzigen",
      move: "Verplaatsen",
      delete: "Verwijderen",
      copy: "Kopiëren",
      paste: "Plakken",
      duplicate: "Dupliceren",
      select_font: "Selecteer lettertype",
      change_font: "Lettertype wijzigen",
      update_global_font: "Site lettertypen bijwerken",
      edit: "Bewerken",
      update: "Bijwerken",
    },
    errors: {
      pageNameEmpty: "Paginanaam mag niet leeg zijn",
      duplicateNameOrUrl:
        "Een andere pagina heeft dezelfde naam of URL ({{otherPage}})",
      invalidUrl: "De URL bevat ongeldige tekens",
      not_image_format: "Ongeldig afbeeldingsformaat",
      not_font_format: "Ongeldig lettertypeformaat",
      exceeded_limit: "Bestandsgrootte limiet overschreden",
      empty_name: "Het naamveld is verplicht",
      empty_password: "Wachtwoord mag niet leeg zijn",
    },
    info: {
      valid_font_formats: "Alleen ttf en woff formaten zijn toegestaan.",
      first_font_is_global:
        "Het eerste lettertype dat je kiest zal worden gebruikt als standaard voor deze site",
      default_font_is: "Standaard lettertype: {{font}}",
    },
    labels: {
      pageName: "Paginanaam",
      friendlyUrl: "Vriendelijke URL",
      imageName: "Naam",
      imageDescription: "Beschrijving",
      or: "Of",
      customUrl: "Aangepaste URL / CSS-gradiënt",
      attendee_fields: "Deelnemer velden",
      b2b_fields: "Netwerk velden",
      b2b_how_to:
        "Kies de informatie die je wilt weergeven voor één deelnemer type door de beschikbare velden te slepen.",
      networking_profile: "Netwerkprofiel",
      attendee_type: "Deelnemer type",
      types_to_show:
        "Netwerkprofielen die op de website moeten worden weergegeven",
      hide_navbar: "Navbar verbergen",
      hide_from_navbar: "Verbergen van de Navbar",
      hide_footer: "Footer verbergen",
      hide_from_footer: "Verbergen van de Footer",
      text: "Tekst",
      open_in_new_tab: "Link openen in nieuw tabblad",
      description: "Beschrijving (optioneel)",
      name: "Naam (verplicht)",
      scoped_css_note:
        "Opmerking: gebruik a.u.b. passend scoped regels in je aangepaste CSS; het gebruik van globale regels kan de stijlen van de rest van je site negatief beïnvloeden.",
      js_note:
        "Opmerking: wees a.u.b. voorzichtig met wat je hier invoert; syntaxis of runtime fouten kunnen je website breken.",
      placeholder_b2b_drop:
        "Drop of schrijf de velden die je wilt gebruiken binnen ${}. Bijvoorbeeld ${Myfield}",
      fonts: "Lettertypen",
      site_fonts: "Lettertypen gebruikt op deze site",
      fonts_in_account: "Lettertypen in je account",
      font_preview: "Voorbeeld",
      max_width: "Site Max Breedte",
      allow_indexing: "Toestaan dat zoekmachines indexeren",
      indexing: "Zoekmachine indexering",
      site_password: "Site wachtwoord",
      enable_site_password: "Site wachtwoord inschakelen",
      backdrop_color: "Achtergrondkleur",
      popup_color: "Popup kleur",
      button_color: "Knop kleur",
      error_msg: "Foutmelding",
      minimum_age: "Minimum leeftijd",
      security: "Beveiliging",
      enable_age_gate: "Leeftijdsrestrictie voor site inschakelen",
      password: "Wachtwoord",
      login_main_color: "Inlog hoofdkleur",
      iframe_note:
        "Om een correcte resizing van de iframe te garanderen, moet de doelwebsite de iframe-resizer bibliotheek script hebben.",
    },
    warnings: {
      create_conference_and_participant:
        "Maak alsjeblieft een conferentie en minimaal één deelnemer aan",
    },
    block_types: {
      [blockTypes.REGISTRATION]: "Registratie",
      [blockTypes.REGISTRATION_TYPES]: "Registratietypes",
      [blockTypes.B2B_MATCHMAKING]: "B2B matchmaking",
      [blockTypes.COOKIE_CONSENT]: "Cookie toestemming",
      [blockTypes.NETWORKING_LOGIN]: "Netwerken inloggen",
      [blockTypes.ATTENDEES]: "Deelnemers",
      [blockTypes.SPEAKERS]: "Sprekers",
      [blockTypes.SPONSORS]: "Sponsoren",
      [blockTypes.NAVBAR]: "Navbar",
      [blockTypes.FOOTER]: "Footer",
      [blockTypes.CUSTOM]: "Aangepast",
      [blockTypes.WORKSHOPS]: "Activiteiten",
      [blockTypes.MAPS]: "Kaarten",
      [blockTypes.HEADERS]: "Headers",
      [blockTypes.EXHIBITORS]: "Tentoonstellers",
      [blockTypes.FREE]: "Vrij blok",
      [blockTypes.CONTACT]: "Contactformulier",
    },
  },
  ar: {
    placeholder: {
      ageGateDescription: "مثال: يجب أن تكون 18 عامًا أو أكبر للدخول.",
      ageGateErrorMsg: "مثال: أنت ليس لديك العمر الكافي لدخول هذا الموقع.",
      blocks: {
        navbar: "اسحب وإفلات كتل شريط التنقل هنا",
        content: "اسحب وإفلات كتل المحتوى هنا",
        footer: "اسحب وإفلات كتل التذييل هنا",
      },
    },
    tabs: {
      stockImages: "صور مخزون",
      userImages: "صور المستخدم",
      uploadImage: "تحميل صورة",
    },
    buttons: {
      previewAs_desktop: "معاينة على سطح المكتب",
      previewAs_tablet: "معاينة على الجهاز اللوحي",
      previewAs_mobile: "معاينة على الهاتف المحمول",
      globalSettings: "الإعدادات العامة",
      preview: "معاينة",
      chooseUiLang: "اختر لغة الواجهة",
      saveAllChanges: "احفظ جميع التغييرات",
      publish: "نشر",
      close: "إغلاق",
      back: "عودة",
      back_to_composer: "العودة إلى المؤلف",
      restore: "استعادة",
      add_content: "إضافة محتوى",
      select_image: "اختيار صورة",
      manage_slides: "إدارة الشرائح",
      add_slide: "إضافة شريحة",
      manage_links: "إدارة الروابط",
      manage_iframe_src: "إدارة رابط Iframe",
      manage_information: "إدارة المعلومات",
      manage_colours: "إدارة الألوان",
      manage_attendee_types: "إدارة أنواع الحضور",
      manage_workshop_categories: "إدارة فئات ورش العمل",
      add_font: "إضافة خط",
      add_link: "إضافة رابط",
      add_color: "إضافة لون",
      apply_changes: "تطبيق التغييرات",
      discard_changes: "إلغاء التغييرات",
      url_image: "صورة من URL",
      upload_image: "رفع صورة",
      edit_code: "تعديل الكود",
      delete_page: "حذف الصفحة",
      duplicate_page: "تكرار الصفحة",
      copy_block: "نسخ الكتلة",
      paste_block: "لصق الكتلة",
      duplicate_block: "تكرار الكتلة",
      copy_content: "نسخ المحتوى",
      paste_content: "لصق المحتوى",
      change_friendly_url: "تغيير URL الصديق",
      move: "نقل",
      delete: "حذف",
      copy: "نسخ",
      paste: "لصق",
      duplicate: "تكرار",
      select_font: "اختيار خط",
      change_font: "تغيير الخط",
      update_global_font: "تحديث خطوط الموقع",
      edit: "تعديل",
      update: "تحديث",
    },
    errors: {
      pageNameEmpty: "اسم الصفحة لا يمكن أن يكون فارغًا",
      duplicateNameOrUrl:
        "تحتوي صفحة أخرى على نفس الاسم أو الرابط ({{otherPage}})",
      invalidUrl: "الرابط يحتوي على أحرف غير صالحة",
      not_image_format: "تنسيق الصورة غير صالح",
      not_font_format: "تنسيق الخط غير صالح",
      exceeded_limit: "تم تجاوز حد حجم الملف",
      empty_name: "حقل الاسم مطلوب",
      empty_password: "لا يمكن أن تكون كلمة المرور فارغة",
    },
    info: {
      valid_font_formats: "تنسيقات الخطوط المسموح بها فقط هي ttf و woff.",
      first_font_is_global:
        "الخط الأول الذي تختاره سيتم استخدامه كافتراضي لهذا الموقع",
      default_font_is: "الخط الافتراضي: {{font}}",
    },
    labels: {
      pageName: "اسم الصفحة",
      friendlyUrl: "رابط ودود",
      imageName: "الاسم",
      imageDescription: "الوصف",
      or: "أو",
      customUrl: "رابط مخصص / تدرج CSS",
      attendee_fields: "حقول الحضور",
      b2b_fields: "حقول الشبكات",
      b2b_how_to:
        "اختر المعلومات التي ترغب في عرضها لنوع مشارك واحد عن طريق سحب الحقول المتاحة.",
      networking_profile: "ملف الشبكات",
      attendee_type: "نوع الحضور",
      types_to_show: "ملفات الشبكات التي ستظهر على الموقع",
      hide_navbar: "إخفاء شريط التنقل",
      hide_from_navbar: "إخفاء من شريط التنقل",
      hide_footer: "إخفاء التذييل",
      hide_from_footer: "إخفاء من التذييل",
      text: "نص",
      open_in_new_tab: "فتح الرابط في تبويب جديد",
      description: "الوصف (اختياري)",
      name: "الاسم (مطلوب)",
      scoped_css_note:
        "ملاحظة: يرجى استخدام قواعد محددة النطاق بشكل مناسب في CSS المخصص الخاص بك؛ استخدام القواعد العالمية قد يؤثر سلبًا على أنماط بقية موقعك.",
      js_note:
        "ملاحظة: يرجى أن تكون واعيًا لما تقوم بإدخاله هنا؛ أي أخطاء في التركيب أو وقت التشغيل قد تؤدي إلى كسر موقعك.",
      placeholder_b2b_drop:
        "قم بسحب أو كتابة الحقول التي ترغب في استخدامها داخل ${}. على سبيل المثال ${Myfield}",
      fonts: "الخطوط",
      site_fonts: "الخطوط المستخدمة في هذا الموقع",
      fonts_in_account: "الخطوط في حسابك",
      font_preview: "معاينة",
      max_width: "أقصى عرض للموقع",
      allow_indexing: "السماح بفهرسة محركات البحث",
      indexing: "فهرسة محركات البحث",
      site_password: "كلمة مرور الموقع",
      enable_site_password: "تفعيل كلمة مرور الموقع",
      backdrop_color: "لون الخلفية",
      popup_color: "لون النافذة المنبثقة",
      button_color: "لون الزر",
      error_msg: "رسالة الخطأ",
      minimum_age: "الحد الأدنى للعمر",
      security: "الأمان",
      enable_age_gate: "تفعيل قيود العمر على الموقع",
      password: "كلمة المرور",
      login_main_color: "لون تسجيل الدخول الرئيسي",
      iframe_note:
        "لضمان تغيير حجم Iframe بشكل صحيح، يجب أن يحتوي الموقع المستهدف على سكربت مكتبة Iframe-resizer.",
    },
    warnings: {
      create_conference_and_participant:
        "يرجى إنشاء مؤتمر ومشارك واحد على الأقل",
    },
    block_types: {
      [blockTypes.REGISTRATION]: "التسجيل",
      [blockTypes.REGISTRATION_TYPES]: "أنواع التسجيل",
      [blockTypes.B2B_MATCHMAKING]: "مطابقة B2B",
      [blockTypes.COOKIE_CONSENT]: "موافقة الكوكيز",
      [blockTypes.NETWORKING_LOGIN]: "تسجيل دخول الشبكات",
      [blockTypes.ATTENDEES]: "الحضور",
      [blockTypes.SPEAKERS]: "المتحدثون",
      [blockTypes.SPONSORS]: "الرعاة",
      [blockTypes.NAVBAR]: "شريط التنقل",
      [blockTypes.FOOTER]: "التذييل",
      [blockTypes.CUSTOM]: "مخصص",
      [blockTypes.WORKSHOPS]: "الأنشطة",
      [blockTypes.MAPS]: "الخرائط",
      [blockTypes.HEADERS]: "الرؤوس",
      [blockTypes.EXHIBITORS]: "المعرضون",
      [blockTypes.FREE]: "كتلة مجانية",
      [blockTypes.CONTACT]: "نموذج الاتصال",
    },
  },
  vi: {
    placeholder: {
      ageGateDescription: "Ví dụ: Bạn phải từ 18 tuổi trở lên để vào.",
      ageGateErrorMsg: "Ví dụ: Bạn không đủ tuổi để vào trang này.",
      blocks: {
        navbar: "Kéo và thả các khối thanh điều hướng ở đây",
        content: "Kéo và thả các khối xây dựng ở đây",
        footer: "Kéo và thả các khối chân ở đây",
      },
    },
    tabs: {
      stockImages: "Hình ảnh Chứng khoán",
      userImages: "Hình ảnh Người dùng",
      uploadImage: "Tải lên Hình ảnh",
    },
    buttons: {
      previewAs_desktop: "Xem trước trên máy tính",
      previewAs_tablet: "Xem trước trên máy tính bảng",
      previewAs_mobile: "Xem trước trên điện thoại di động",
      globalSettings: "Cài đặt toàn cầu",
      preview: "Xem trước",
      chooseUiLang: "Chọn ngôn ngữ giao diện",
      saveAllChanges: "Lưu tất cả thay đổi",
      publish: "Xuất bản",
      close: "Đóng",
      back: "Quay lại",
      back_to_composer: "Quay lại Composer",
      restore: "Khôi phục",
      add_content: "Thêm nội dung",
      select_image: "Chọn hình ảnh",
      manage_slides: "Quản lý slide",
      add_slide: "Thêm slide",
      manage_links: "Quản lý liên kết",
      manage_iframe_src: "Quản lý liên kết Iframe",
      manage_information: "Quản lý thông tin",
      manage_colours: "Quản lý màu sắc",
      manage_attendee_types: "Quản lý loại người tham dự",
      manage_workshop_categories: "Quản lý danh mục workshop",
      add_font: "Thêm phông chữ",
      add_link: "Thêm liên kết",
      add_color: "Thêm màu",
      apply_changes: "Áp dụng thay đổi",
      discard_changes: "Bỏ qua thay đổi",
      url_image: "Hình ảnh từ URL",
      upload_image: "Tải lên hình ảnh",
      edit_code: "Chỉnh sửa mã",
      delete_page: "Xóa trang",
      duplicate_page: "Sao chép trang",
      copy_block: "Sao chép khối",
      paste_block: "Dán khối",
      duplicate_block: "Sao chép khối",
      copy_content: "Sao chép nội dung",
      paste_content: "Dán nội dung",
      change_friendly_url: "Thay đổi URL thân thiện",
      move: "Di chuyển",
      delete: "Xóa",
      copy: "Sao chép",
      paste: "Dán",
      duplicate: "Sao chép",
      select_font: "Chọn phông chữ",
      change_font: "Thay đổi phông chữ",
      update_global_font: "Cập nhật phông chữ của trang",
      edit: "Chỉnh sửa",
      update: "Cập nhật",
    },
    errors: {
      pageNameEmpty: "Tên trang không thể để trống",
      duplicateNameOrUrl: "Một trang khác có cùng tên hoặc URL ({{otherPage}})",
      invalidUrl: "URL chứa ký tự không hợp lệ",
      not_image_format: "Định dạng hình ảnh không hợp lệ",
      not_font_format: "Định dạng phông chữ không hợp lệ",
      exceeded_limit: "Giới hạn kích thước tệp đã vượt quá",
      empty_name: "Trường tên là bắt buộc",
      empty_password: "Mật khẩu không thể để trống",
    },
    info: {
      valid_font_formats: "Chỉ chấp nhận định dạng ttf và woff.",
      first_font_is_global:
        "Phông chữ đầu tiên bạn chọn sẽ được sử dụng mặc định cho trang này",
      default_font_is: "Phông chữ mặc định: {{font}}",
    },
    labels: {
      pageName: "Tên trang",
      friendlyUrl: "URL thân thiện",
      imageName: "Tên",
      imageDescription: "Mô tả",
      or: "Hoặc",
      customUrl: "URL Tùy chỉnh / Gradient CSS",
      attendee_fields: "Trường thông tin người tham dự",
      b2b_fields: "Trường thông tin kết nối",
      b2b_how_to:
        "Chọn thông tin để hiển thị cho một loại người tham dự bằng cách kéo các trường có sẵn.",
      networking_profile: "Hồ sơ kết nối",
      attendee_type: "Loại người tham dự",
      types_to_show: "Hồ sơ kết nối sẽ hiển thị trên trang web",
      hide_navbar: "Ẩn thanh điều hướng",
      hide_from_navbar: "Ẩn khỏi thanh điều hướng",
      hide_footer: "Ẩn chân trang",
      hide_from_footer: "Ẩn khỏi chân trang",
      text: "Văn bản",
      open_in_new_tab: "Mở liên kết trong tab mới",
      description: "Mô tả (tùy chọn)",
      name: "Tên (bắt buộc)",
      scoped_css_note:
        "Lưu ý: vui lòng sử dụng các quy tắc có phạm vi hợp lý trong CSS tùy chỉnh của bạn; sử dụng quy tắc toàn cầu có thể ảnh hưởng tiêu cực đến kiểu giãn cách của phần còn lại trang.",
      js_note:
        "Lưu ý: hãy cẩn thận với những gì bạn chèn vào đây; bất kỳ lỗi cú pháp hoặc lỗi thời gian chạy nào cũng có thể làm hỏng trang web của bạn.",
      placeholder_b2b_drop:
        "Kéo hoặc viết các trường bạn muốn sử dụng bên trong ${}. Ví dụ ${Myfield}",
      fonts: "Phông chữ",
      site_fonts: "Phông chữ được sử dụng trong trang",
      fonts_in_account: "Phông chữ trong tài khoản của bạn",
      font_preview: "Xem trước",
      max_width: "Độ rộng tối đa của trang",
      allow_indexing: "Cho phép công cụ tìm kiếm lập chỉ mục",
      indexing: "Lập chỉ mục công cụ tìm kiếm",
      site_password: "Mật khẩu trang",
      enable_site_password: "Bật mật khẩu trang",
      backdrop_color: "Màu nền",
      popup_color: "Màu của pop-up",
      button_color: "Màu nút",
      error_msg: "Thông báo lỗi",
      minimum_age: "Độ tuổi tối thiểu",
      security: "Bảo mật",
      enable_age_gate: "Bật giới hạn độ tuổi cho trang",
      password: "Mật khẩu",
      login_main_color: "Màu chính của đăng nhập",
      iframe_note:
        "Để đảm bảo Iframe được thay đổi kích thước chính xác, trang web mục tiêu phải có đoạn mã thư viện iframe-resizer.",
    },
    warnings: {
      create_conference_and_participant:
        "Xin vui lòng tạo một hội nghị và ít nhất một người tham dự",
    },
    block_types: {
      [blockTypes.REGISTRATION]: "Đăng ký",
      [blockTypes.REGISTRATION_TYPES]: "Các loại đăng ký",
      [blockTypes.B2B_MATCHMAKING]: "Kết nối B2B",
      [blockTypes.COOKIE_CONSENT]: "Sự đồng ý về cookie",
      [blockTypes.NETWORKING_LOGIN]: "Đăng nhập kết nối",
      [blockTypes.ATTENDEES]: "Người tham dự",
      [blockTypes.SPEAKERS]: "Diễn giả",
      [blockTypes.SPONSORS]: "Nhà tài trợ",
      [blockTypes.NAVBAR]: "Thanh điều hướng",
      [blockTypes.FOOTER]: "Chân trang",
      [blockTypes.CUSTOM]: "Tùy chỉnh",
      [blockTypes.WORKSHOPS]: "Hoạt động",
      [blockTypes.MAPS]: "Bản đồ",
      [blockTypes.HEADERS]: "Tiêu đề",
      [blockTypes.EXHIBITORS]: "Nhà triển lãm",
      [blockTypes.FREE]: "Khối miễn phí",
      [blockTypes.CONTACT]: "Mẫu liên hệ",
    },
  },
  zh: {
    placeholder: {
      ageGateDescription: "例如：您必须年满18岁才能进入。",
      ageGateErrorMsg: "例如：您还未达到进入该网站的年龄。",
      blocks: {
        navbar: "将导航栏块拖放到这里",
        content: "将构建块拖放到这里",
        footer: "将页脚块拖放到这里",
      },
    },
    tabs: {
      stockImages: "图库图片",
      userImages: "用户图片",
      uploadImage: "上传图片",
    },
    buttons: {
      previewAs_desktop: "桌面预览",
      previewAs_tablet: "平板预览",
      previewAs_mobile: "移动预览",
      globalSettings: "全局设置",
      preview: "预览",
      chooseUiLang: "选择界面语言",
      saveAllChanges: "保存所有更改",
      publish: "发布",
      close: "关闭",
      back: "返回",
      back_to_composer: "返回到创作者",
      restore: "恢复",
      add_content: "添加内容",
      select_image: "选择图像",
      manage_slides: "管理幻灯片",
      add_slide: "添加幻灯片",
      manage_links: "管理链接",
      manage_iframe_src: "管理Iframe链接",
      manage_information: "管理信息",
      manage_colours: "管理颜色",
      manage_attendee_types: "管理参与者类型",
      manage_workshop_categories: "管理工作坊类别",
      add_font: "添加字体",
      add_link: "添加链接",
      add_color: "添加颜色",
      apply_changes: "应用更改",
      discard_changes: "放弃更改",
      url_image: "来自URL的图像",
      upload_image: "上传图像",
      edit_code: "编辑代码",
      delete_page: "删除页面",
      duplicate_page: "复制页面",
      copy_block: "复制区块",
      paste_block: "粘贴区块",
      duplicate_block: "复制区块",
      copy_content: "复制内容",
      paste_content: "粘贴内容",
      change_friendly_url: "更改友好URL",
      move: "移动",
      delete: "删除",
      copy: "复制",
      paste: "粘贴",
      duplicate: "复制",
      select_font: "选择字体",
      change_font: "更改字体",
      update_global_font: "更新网站字体",
      edit: "编辑",
      update: "更新",
    },
    errors: {
      pageNameEmpty: "页面名称不能为空",
      duplicateNameOrUrl: "另一个页面具有相同的名称或网址 ({{otherPage}})",
      invalidUrl: "网址包含无效字符",
      not_image_format: "无效的图像格式",
      not_font_format: "无效的字体格式",
      exceeded_limit: "文件大小限制超出",
      empty_name: "名称字段为必填项",
      empty_password: "密码不能为空",
    },
    info: {
      valid_font_formats: "只允许ttf和woff格式。",
      first_font_is_global: "您选择的第一个字体将被用作该网站的默认字体",
      default_font_is: "默认字体：{{font}}",
    },
    labels: {
      pageName: "页面名称",
      friendlyUrl: "友好的网址",
      imageName: "名称",
      imageDescription: "描述",
      or: "或者",
      customUrl: "自定义URL / CSS渐变",
      attendee_fields: "参与者字段",
      b2b_fields: "网络字段",
      b2b_how_to: "通过拖动可用字段选择显示给一个参与者类型的信息。",
      networking_profile: "网络资料",
      attendee_type: "参与者类型",
      types_to_show: "在网站上显示的网络资料",
      hide_navbar: "隐藏导航栏",
      hide_from_navbar: "从导航栏隐藏",
      hide_footer: "隐藏页脚",
      hide_from_footer: "从页脚隐藏",
      text: "文本",
      open_in_new_tab: "在新标签中打开链接",
      description: "描述（可选）",
      name: "名称（必填）",
      scoped_css_note:
        "注意：请在自定义CSS中使用适当的范围规则；使用全局规则可能会对您网站的其他样式产生负面影响。",
      js_note:
        "注意：请谨慎插入内容；任何语法或运行时错误可能会导致您的网站出现故障。",
      placeholder_b2b_drop:
        "将您要使用的字段拖入或写入${}中。例如：${Myfield}",
      fonts: "字体",
      site_fonts: "该网站使用的字体",
      fonts_in_account: "您账户中的字体",
      font_preview: "预览",
      max_width: "网站最大宽度",
      allow_indexing: "允许搜索引擎索引",
      indexing: "搜索引擎索引",
      site_password: "网站密码",
      enable_site_password: "启用网站密码",
      backdrop_color: "背景颜色",
      popup_color: "弹出颜色",
      button_color: "按钮颜色",
      error_msg: "错误消息",
      minimum_age: "最低年龄",
      security: "安全性",
      enable_age_gate: "启用网站年龄限制",
      password: "密码",
      login_main_color: "登录主颜色",
      iframe_note:
        "为保证iframe的正确调整大小，目标网站必须有iframe-resizer库脚本。",
    },
    warnings: {
      create_conference_and_participant: "请创建会议并至少添加一个参与者",
    },
    block_types: {
      [blockTypes.REGISTRATION]: "注册",
      [blockTypes.REGISTRATION_TYPES]: "注册类型",
      [blockTypes.B2B_MATCHMAKING]: "B2B 配对",
      [blockTypes.COOKIE_CONSENT]: "Cookie 同意",
      [blockTypes.NETWORKING_LOGIN]: "网络登录",
      [blockTypes.ATTENDEES]: "参与者",
      [blockTypes.SPEAKERS]: "演讲者",
      [blockTypes.SPONSORS]: "赞助商",
      [blockTypes.NAVBAR]: "导航栏",
      [blockTypes.FOOTER]: "页脚",
      [blockTypes.CUSTOM]: "自定义",
      [blockTypes.WORKSHOPS]: "活动",
      [blockTypes.MAPS]: "地图",
      [blockTypes.HEADERS]: "标题",
      [blockTypes.EXHIBITORS]: "参展商",
      [blockTypes.FREE]: "免费区块",
      [blockTypes.CONTACT]: "联系表单",
    },
  },
  "zh-TW": {
    placeholder: {
      ageGateDescription: "例如：您必須年滿 18 歲才能進入。",
      ageGateErrorMsg: "例如：您年齡不足，無法進入此網站。",
      blocks: {
        navbar: "將導航欄區塊拖放在這裡",
        content: "將內容區塊拖放在這裡",
        footer: "將頁腳區塊拖放在這裡",
      },
    },
    tabs: {
      stockImages: "庫存圖片",
      userImages: "用戶圖片",
      uploadImage: "上傳圖片",
    },
    buttons: {
      previewAs_desktop: "桌面預覽",
      previewAs_tablet: "平板預覽",
      previewAs_mobile: "手機預覽",
      globalSettings: "全域設定",
      preview: "預覽",
      chooseUiLang: "選擇 UI 語言",
      saveAllChanges: "保存所有變更",
      publish: "發布",
      close: "關閉",
      back: "返回",
      back_to_composer: "返回到設計器",
      restore: "還原",
      add_content: "新增內容",
      select_image: "選擇圖片",
      manage_slides: "管理幻燈片",
      add_slide: "新增幻燈片",
      manage_links: "管理連結",
      manage_iframe_src: "管理 Iframe 連結",
      manage_information: "管理資訊",
      manage_colours: "管理顏色",
      manage_attendee_types: "管理與會者類型",
      manage_workshop_categories: "管理工作坊類別",
      add_font: "新增字型",
      add_link: "新增連結",
      add_color: "新增顏色",
      apply_changes: "應用變更",
      discard_changes: "放棄變更",
      url_image: "來自 URL 的圖片",
      upload_image: "上傳圖片",
      edit_code: "編輯代碼",
      delete_page: "刪除頁面",
      duplicate_page: "複製頁面",
      copy_block: "複製區塊",
      paste_block: "粘貼區塊",
      duplicate_block: "複製區塊",
      copy_content: "複製內容",
      paste_content: "粘貼內容",
      change_friendly_url: "更改友好 URL",
      move: "移動",
      delete: "刪除",
      copy: "複製",
      paste: "粘貼",
      duplicate: "複製",
      select_font: "選擇字型",
      change_font: "更改字型",
      update_global_font: "更新網站字型",
      edit: "編輯",
      update: "更新",
    },
    errors: {
      pageNameEmpty: "頁面名稱不能為空",
      duplicateNameOrUrl: "另一個頁面的名稱或 URL 相同 ({{otherPage}})",
      invalidUrl: "該 URL 包含無效字符",
      not_image_format: "無效的圖片格式",
      not_font_format: "無效的字型格式",
      exceeded_limit: "文件大小超出限制",
      empty_name: "名稱欄位為必填",
      empty_password: "密碼不能為空",
    },
    info: {
      valid_font_formats: "僅允許 ttf 和 woff 格式。",
      first_font_is_global: "您選擇的第一個字型將用作此網站的默認字型",
      default_font_is: "默認字型為：{{font}}",
    },
    labels: {
      pageName: "頁面名稱",
      friendlyUrl: "友好 URL",
      imageName: "名稱",
      imageDescription: "描述",
      or: "或",
      customUrl: "自定義 URL / CSS 漸變",
      attendee_fields: "參加者欄位",
      b2b_fields: "網絡欄位",
      b2b_how_to: "通過拖放可用欄位來選擇要顯示的某一參加者類型的信息。",
      networking_profile: "網絡資料",
      attendee_type: "參加者類型",
      types_to_show: "網站上顯示的網絡資料",
      hide_navbar: "隱藏導航欄",
      hide_from_navbar: "從導航欄隱藏",
      hide_footer: "隱藏頁腳",
      hide_from_footer: "從頁腳隱藏",
      text: "文本",
      open_in_new_tab: "在新標籤中打開連結",
      description: "描述（可選）",
      name: "名稱（必填）",
      scoped_css_note:
        "注意：請在您的自定義 CSS 中使用適當的範疇規則；使用全域規則可能會對您的網站的其他樣式產生負面影響。",
      js_note:
        "注意：請注意您在此處插入的內容；任何語法或運行時錯誤可能會中斷您的網站。",
      placeholder_b2b_drop:
        "將您想要在 ${} 中使用的欄位拖放或寫入。例如 ${Myfield}",
      fonts: "字型",
      site_fonts: "此網站使用的字型",
      fonts_in_account: "您帳戶中的字型",
      font_preview: "預覽",
      max_width: "網站最大寬度",
      allow_indexing: "允許搜索引擎索引",
      indexing: "搜索引擎索引",
      site_password: "網站密碼",
      enable_site_password: "啟用網站密碼",
      backdrop_color: "背景顏色",
      popup_color: "彈出顏色",
      button_color: "按鈕顏色",
      error_msg: "錯誤信息",
      minimum_age: "最低年齡",
      security: "安全性",
      enable_age_gate: "啟用網站年齡限制",
      password: "密碼",
      login_main_color: "登錄主顏色",
      iframe_note:
        "為保證 iframe 的正確調整大小，目標網站必須具備 iframe-resizer 庫腳本。",
    },
    warnings: {
      create_conference_and_participant: "請創建一個會議和至少一個參加者",
    },
    block_types: {
      [blockTypes.REGISTRATION]: "註冊",
      [blockTypes.REGISTRATION_TYPES]: "註冊類型",
      [blockTypes.B2B_MATCHMAKING]: "B2B 配對",
      [blockTypes.COOKIE_CONSENT]: "Cookie 同意",
      [blockTypes.NETWORKING_LOGIN]: "網絡登錄",
      [blockTypes.ATTENDEES]: "參加者",
      [blockTypes.SPEAKERS]: "演講者",
      [blockTypes.SPONSORS]: "贊助商",
      [blockTypes.NAVBAR]: "導航欄",
      [blockTypes.FOOTER]: "頁腳",
      [blockTypes.CUSTOM]: "自定義",
      [blockTypes.WORKSHOPS]: "活動",
      [blockTypes.MAPS]: "地圖",
      [blockTypes.HEADERS]: "標題",
      [blockTypes.EXHIBITORS]: "展商",
      [blockTypes.FREE]: "免費區塊",
      [blockTypes.CONTACT]: "聯絡表單",
    },
  },
  ko: {
    placeholder: {
      ageGateDescription: "예: 입장하려면 18세 이상이어야 합니다.",
      ageGateErrorMsg: "예: 이 사이트에 들어가기에는 나이가 부족합니다.",
      blocks: {
        navbar: "내비게이션 바 블록을 여기로 드래그 앤 드롭하세요",
        content: "빌딩 블록을 여기로 드래그 앤 드롭하세요",
        footer: "푸터 블록을 여기로 드래그 앤 드롭하세요",
      },
    },
    tabs: {
      stockImages: "스톡 이미지",
      userImages: "사용자 이미지",
      uploadImage: "이미지 업로드",
    },
    buttons: {
      previewAs_desktop: "데스크탑 미리보기",
      previewAs_tablet: "태블릿 미리보기",
      previewAs_mobile: "모바일 미리보기",
      globalSettings: "전역 설정",
      preview: "미리보기",
      chooseUiLang: "UI 언어 선택",
      saveAllChanges: "모든 변경사항 저장",
      publish: "게시",
      close: "닫기",
      back: "뒤로",
      back_to_composer: "작곡 도구로 돌아가기",
      restore: "복원",
      add_content: "내용 추가",
      select_image: "이미지 선택",
      manage_slides: "슬라이드 관리",
      add_slide: "슬라이드 추가",
      manage_links: "링크 관리",
      manage_iframe_src: "Iframe 링크 관리",
      manage_information: "정보 관리",
      manage_colours: "색상 관리",
      manage_attendee_types: "참석자 유형 관리",
      manage_workshop_categories: "워크숍 카테고리 관리",
      add_font: "글꼴 추가",
      add_link: "링크 추가",
      add_color: "색상 추가",
      apply_changes: "변경 사항 적용",
      discard_changes: "변경 사항 폐기",
      url_image: "URL에서 이미지",
      upload_image: "이미지 업로드",
      edit_code: "코드 편집",
      delete_page: "페이지 삭제",
      duplicate_page: "페이지 복사",
      copy_block: "블록 복사",
      paste_block: "블록 붙여넣기",
      duplicate_block: "블록 중복",
      copy_content: "내용 복사",
      paste_content: "내용 붙여넣기",
      change_friendly_url: "친숙한 URL 변경",
      move: "이동",
      delete: "삭제",
      copy: "복사",
      paste: "붙여넣기",
      duplicate: "중복",
      select_font: "글꼴 선택",
      change_font: "글꼴 변경",
      update_global_font: "사이트 글꼴 업데이트",
      edit: "편집",
      update: "업데이트",
    },
    errors: {
      pageNameEmpty: "페이지 이름은 비워둘 수 없습니다",
      duplicateNameOrUrl:
        "다른 페이지에 동일한 이름이나 URL이 있습니다 ({{otherPage}})",
      invalidUrl: "URL에 유효하지 않은 문자가 포함되어 있습니다",
      not_image_format: "유효하지 않은 이미지 형식입니다.",
      not_font_format: "유효하지 않은 글꼴 형식입니다.",
      exceeded_limit: "파일 크기 제한을 초과했습니다.",
      empty_name: "이름 필드는 필수입니다.",
      empty_password: "비밀번호는 비어 있을 수 없습니다.",
    },
    info: {
      valid_font_formats: "ttf 및 woff 형식만 허용됩니다.",
      first_font_is_global:
        "선택한 첫 번째 글꼴은 이 사이트의 기본 글꼴로 사용됩니다.",
      default_font_is: "기본 글꼴: {{font}}",
    },
    labels: {
      pageName: "페이지 이름",
      friendlyUrl: "친숙한 URL",
      imageName: "이름",
      imageDescription: "설명",
      or: "또는",
      customUrl: "사용자 정의 URL / CSS 그라디언트",
      attendee_fields: "참석자 필드",
      b2b_fields: "네트워킹 필드",
      b2b_how_to:
        "사용 가능한 필드를 드래그하여 어떤 참가자 유형에 대한 정보를 표시할지 선택하십시오.",
      networking_profile: "네트워킹 프로필",
      attendee_type: "참석자 유형",
      types_to_show: "웹사이트에 표시될 네트워킹 프로필",
      hide_navbar: "내비게이션 바 숨기기",
      hide_from_navbar: "내비게이션 바에서 숨기기",
      hide_footer: "푸터 숨기기",
      hide_from_footer: "푸터에서 숨기기",
      text: "텍스트",
      open_in_new_tab: "새 탭에서 링크 열기",
      description: "설명(선택)",
      name: "이름(필수)",
      scoped_css_note:
        "참고: 사용자 정의 CSS에서 적절하게 범위가 지정된 규칙을 사용하십시오; 전역 규칙을 사용하면 사이트 나머지의 스타일에 부정적인 영향을 미칠 수 있습니다.",
      js_note:
        "참고: 여기에 삽입하는 내용에 주의하십시오; 구문 또는 런타임 오류가 발생하면 웹사이트가 작동하지 않을 수 있습니다.",
      placeholder_b2b_drop:
        "${} 내부에 사용하고자 하는 필드를 드래그하거나 작성하십시오. 예: ${Myfield}",
      fonts: "글꼴",
      site_fonts: "이 사이트에서 사용되는 글꼴",
      fonts_in_account: "귀하의 계정에 있는 글꼴",
      font_preview: "미리보기",
      max_width: "사이트 최대 너비",
      allow_indexing: "검색 엔진 인덱싱 허용",
      indexing: "검색 엔진 인덱싱",
      site_password: "사이트 비밀번호",
      enable_site_password: "사이트 비밀번호 활성화",
      backdrop_color: "배경색",
      popup_color: "팝업 색상",
      button_color: "버튼 색상",
      error_msg: "오류 메시지",
      minimum_age: "최소 연령",
      security: "보안",
      enable_age_gate: "사이트에 대한 연령 제한 활성화",
      password: "비밀번호",
      login_main_color: "로그인 주요 색상",
      iframe_note:
        "iframe의 올바른 크기 조정을 보장하려면 대상 웹사이트에 iframe-resizer 라이브러리 스크립트가 있어야 합니다.",
    },
    warnings: {
      create_conference_and_participant:
        "컨퍼런스와 최소 한 명의 참가자를 생성하십시오.",
    },
    block_types: {
      [blockTypes.REGISTRATION]: "등록",
      [blockTypes.REGISTRATION_TYPES]: "등록 유형",
      [blockTypes.B2B_MATCHMAKING]: "B2B 매칭",
      [blockTypes.COOKIE_CONSENT]: "쿠키 동의",
      [blockTypes.NETWORKING_LOGIN]: "네트워킹 로그인",
      [blockTypes.ATTENDEES]: "참석자",
      [blockTypes.SPEAKERS]: "발표자",
      [blockTypes.SPONSORS]: "스폰서",
      [blockTypes.NAVBAR]: "내비게이션 바",
      [blockTypes.FOOTER]: "푸터",
      [blockTypes.CUSTOM]: "사용자 정의",
      [blockTypes.WORKSHOPS]: "활동",
      [blockTypes.MAPS]: "지도",
      [blockTypes.HEADERS]: "헤더",
      [blockTypes.EXHIBITORS]: "출품자",
      [blockTypes.FREE]: "무료 블록",
      [blockTypes.CONTACT]: "연락처 양식",
    },
  },
  ja: {
    placeholder: {
      ageGateDescription: "例: 入場するには18歳以上である必要があります。",
      ageGateErrorMsg:
        "例: あなたはこのサイトに入場するには十分な年齢ではありません。",
      blocks: {
        navbar: "ナビゲーションバーのブロックをここにドラッグ＆ドロップ",
        content: "ビルディングブロックをここにドラッグ＆ドロップ",
        footer: "フッターブロックをここにドラッグ＆ドロップ",
      },
    },
    tabs: {
      stockImages: "ストック画像",
      userImages: "ユーザー画像",
      uploadImage: "画像をアップロード",
    },
    buttons: {
      previewAs_desktop: "デスクトップのプレビュー",
      previewAs_tablet: "タブレットのプレビュー",
      previewAs_mobile: "モバイルのプレビュー",
      globalSettings: "グローバル設定",
      preview: "プレビュー",
      chooseUiLang: "UI言語を選択",
      saveAllChanges: "すべての変更を保存",
      publish: "公開",
      close: "閉じる",
      back: "戻る",
      back_to_composer: "作成者に戻る",
      restore: "復元",
      add_content: "コンテンツを追加",
      select_image: "画像を選択",
      manage_slides: "スライドを管理",
      add_slide: "スライドを追加",
      manage_links: "リンクを管理",
      manage_iframe_src: "Iframeリンクを管理",
      manage_information: "情報を管理",
      manage_colours: "色を管理",
      manage_attendee_types: "参加者タイプを管理",
      manage_workshop_categories: "ワークショップカテゴリーを管理",
      add_font: "フォントを追加",
      add_link: "リンクを追加",
      add_color: "色を追加",
      apply_changes: "変更を適用",
      discard_changes: "変更を破棄",
      url_image: "URLからの画像",
      upload_image: "画像をアップロード",
      edit_code: "コードを編集",
      delete_page: "ページを削除",
      duplicate_page: "ページを複製",
      copy_block: "ブロックをコピー",
      paste_block: "ブロックを貼り付け",
      duplicate_block: "ブロックを複製",
      copy_content: "コンテンツをコピー",
      paste_content: "コンテンツを貼り付け",
      change_friendly_url: "フレンドリーURLを変更",
      move: "移動",
      delete: "削除",
      copy: "コピー",
      paste: "貼り付け",
      duplicate: "複製",
      select_font: "フォントを選択",
      change_font: "フォントを変更",
      update_global_font: "サイトのフォントを更新",
      edit: "編集",
      update: "更新",
    },
    errors: {
      pageNameEmpty: "ページ名は空にできません",
      duplicateNameOrUrl:
        "別のページが同じ名前またはURLを持っています ({{otherPage}})",
      invalidUrl: "URLに無効な文字が含まれています",
      not_image_format: "無効な画像形式",
      not_font_format: "無効なフォント形式",
      exceeded_limit: "ファイルサイズ制限を超えました",
      empty_name: "名前フィールドは必須です",
      empty_password: "パスワードは空にできません",
    },
    info: {
      valid_font_formats: "ttfおよびwoff形式のみが許可されています。",
      first_font_is_global:
        "最初に選択したフォントは、このサイトのデフォルトとして使用されます",
      default_font_is: "デフォルトフォント: {{font}}",
    },
    labels: {
      pageName: "ページ名",
      friendlyUrl: "フレンドリーURL",
      imageName: "名前",
      imageDescription: "説明",
      or: "または",
      customUrl: "カスタムURL / CSSグラデーション",
      attendee_fields: "参加者フィールド",
      b2b_fields: "ネットワーキングフィールド",
      b2b_how_to:
        "利用可能なフィールドをドラッグして、1つの参加者タイプに表示する情報を選択します。",
      networking_profile: "ネットワーキングプロフィール",
      attendee_type: "参加者タイプ",
      types_to_show: "ウェブサイトに表示されるネットワーキングプロフィール",
      hide_navbar: "ナビゲーションバーを隠す",
      hide_from_navbar: "ナビゲーションバーから隠す",
      hide_footer: "フッターを隠す",
      hide_from_footer: "フッターから隠す",
      text: "テキスト",
      open_in_new_tab: "新しいタブでリンクを開く",
      description: "説明（オプション）",
      name: "名前（必須）",
      scoped_css_note:
        "注意: カスタムCSSで適切なスコープのルールを使用してください; グローバルなルールを使用すると、サイトの他のスタイルに悪影響を及ぼす可能性があります。",
      js_note:
        "注意: ここに挿入するものには注意してください; 構文エラーやランタイムエラーは、ウェブサイトを壊す可能性があります。",
      placeholder_b2b_drop:
        "${}内に使用したいフィールドをドロップまたは記述してください。例えば、${Myfield}",
      fonts: "フォント",
      site_fonts: "このサイトで使用されるフォント",
      fonts_in_account: "アカウント内のフォント",
      font_preview: "プレビュー",
      max_width: "サイト最大幅",
      allow_indexing: "検索エンジンのインデックスを許可",
      indexing: "検索エンジンのインデックス",
      site_password: "サイトパスワード",
      enable_site_password: "サイトパスワードを有効にする",
      backdrop_color: "バックドロップカラー",
      popup_color: "ポップアップカラー",
      button_color: "ボタンカラー",
      error_msg: "エラーメッセージ",
      minimum_age: "最低年齢",
      security: "セキュリティ",
      enable_age_gate: "サイトに年齢制限を有効にする",
      password: "パスワード",
      login_main_color: "ログインメインカラー",
      iframe_note:
        "iframeの正しいリサイズを保証するために、ターゲットウェブサイトにはiframe-resizerライブラリスクリプトが必要です。",
    },
    warnings: {
      create_conference_and_participant:
        "カンファレンスと少なくとも1人の参加者を作成してください",
    },
    block_types: {
      [blockTypes.REGISTRATION]: "登録",
      [blockTypes.REGISTRATION_TYPES]: "登録タイプ",
      [blockTypes.B2B_MATCHMAKING]: "B2Bマッチメイキング",
      [blockTypes.COOKIE_CONSENT]: "クッキー同意",
      [blockTypes.NETWORKING_LOGIN]: "ネットワーキングログイン",
      [blockTypes.ATTENDEES]: "参加者",
      [blockTypes.SPEAKERS]: "スピーカー",
      [blockTypes.SPONSORS]: "スポンサー",
      [blockTypes.NAVBAR]: "ナビゲーションバー",
      [blockTypes.FOOTER]: "フッター",
      [blockTypes.CUSTOM]: "カスタム",
      [blockTypes.WORKSHOPS]: "アクティビティ",
      [blockTypes.MAPS]: "地図",
      [blockTypes.HEADERS]: "ヘッダー",
      [blockTypes.EXHIBITORS]: "出展者",
      [blockTypes.FREE]: "フリーブロック",
      [blockTypes.CONTACT]: "連絡先フォーム",
    },
  },
}
