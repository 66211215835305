import React, { Component } from 'react';
import injectSheet from 'react-jss';
import BackIcon from '@material-ui/icons/ArrowBack';
import TranslatableButton from './TranslatableButton';
import Tabs from './Tabs';
import cssConstants from '../constants/css';

const styles = theme => ({
  FullScreenModal: {
    position: 'fixed',
    top: cssConstants.navBar.height,
    width: '100%',
    boxSizing: 'border-box',
    bottom: 0,
    left: 0,
    padding: '1rem 0 0 0',
    zIndex: 1050,
    background: theme.palette.primary.light,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  innerContainer: {
    background: theme.palette.primary.dark,
    padding: '1rem',
    overflow: 'auto',
    flex: '1'
  },
  uploaderContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  customUrlContainer: {
    textAlign: 'center',
  },
  backButton: {
    alignSelf: 'baseline',
    marginLeft: '1rem',
    marginBottom: '-1rem',
  },
  iconButton: {
    marginRight: '-0.75rem'
  },
  inputRoot: {
    width: '25em'
  }
});

class FullScreenModal extends Component {
  render() {
    const { classes, tabs, currentTab, setCurrentTab, closeModal, translateTabs } = this.props;

    const tabInfo = Object.keys(tabs).map(tabName => ({
      key: tabName,
      onClick: setCurrentTab(tabName),
      active: currentTab === tabName,
      name: tabName,
    }))

    return (
      <div className={classes.FullScreenModal}>
        <TranslatableButton
          onClick={closeModal}
          leftIcon={<BackIcon />}
          tPath={'buttons.back'}
          buttonProps={{ className: classes.backButton }}
        />
        <Tabs items={tabInfo} translateTabs={translateTabs} />
        <div className={classes.innerContainer}>
          {tabs[currentTab]}
        </div>
      </div>
    );
  }

}

export default injectSheet(styles)(FullScreenModal);
