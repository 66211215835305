import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Settings from '@material-ui/icons/Settings';
import ResponsiveModesMenu from './ResponsiveModesMenu';
import LanguagesMenu from '../components/LanguagesMenu';
import SaveButton from '../containers/SaveButton';
import PublishButton from '../containers/PublishButton';
import cssConstants from '../constants/css';
import logo from '../assets/logo-eventtia-white.png';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const styles = {
  Toolbar: {
    justifyContent: 'space-between',
    height: cssConstants.navBar.height,
    minHeight: 0,
  },
  logo: {
    display: 'inline-block',
    '& img': {
      display: 'block',
      maxHeight: cssConstants.navBar.height
    }
  },
  group: {
    display: 'flex'
  },
  link: {
    color: 'inherit'
  },
  saveButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48
  }
};

class NavBar extends Component {

  render() {
    const { classes, match, t } = this.props;

    return (
      <AppBar position="static">
        <Toolbar className={classes.Toolbar}>
          <Link className={classes.logo} to={`/${match.params.locale}`}><img src={logo} alt="logo-eventtia-white" /></Link>
          <ResponsiveModesMenu />
          <div className={classes.group}>
            <Tooltip title={t('buttons.globalSettings')}>
              <Link to={`/${match.params.locale}/settings/`} className={classes.link}>
                <IconButton color="inherit" aria-label="Settings">
                  <Settings />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title={t('buttons.preview')}>
              <Link to={`/${match.params.locale}/preview/`} className={classes.link}>
                <IconButton color="inherit" aria-label="OpenInNew">
                  <OpenInNew />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title={t('buttons.chooseUiLang')}>
              <span>
                <LanguagesMenu currentLocale={match.params.locale} />
              </span>
            </Tooltip>
            <Tooltip title={t('buttons.saveAllChanges')}>
              <span className={classes.saveButton}>
                <SaveButton />
              </span>
            </Tooltip>
            <Tooltip title={t('buttons.publish')}>
              <span className={classes.saveButton}>
                <PublishButton />
              </span>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
    );
  }

}

export default translate('Forms')(injectSheet(styles)(NavBar));
